import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { useHistory } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  get_author,
  get_category,
  add_quotes,
  notify_all_quotes,
} from "../../DAL/quotes/quotes";
import { useSnackbar } from "notistack";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import Fab from "@material-ui/core/Fab";
import Chip from "@material-ui/core/Chip";
var moment = require("moment"); // require

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: "#37CD77",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    marginTop: "3%",
    color: "white",

    fontSize: "15px",
  },
  txt: {
    color: "black",
    marginTop: "4%",
    marginBottom: 20,
  },
  alert: {
    width: "100%",
    marginBottom: 20,
  },
  avtr: {
    width: 80,
    height: 80,
    cursor: "pointer",
    backgroundColor: "#37CD77",
    color: "white",
  },
  img: {
    width: "100%",
    height: "100%",
  },
  blog_img: {
    marginTop: 10,
    width: 550,
    height: 300,
  },
  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
  },
}));

export default function AddQuotes(props) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [authordata, setAuthordata] = React.useState([]);
  const [categorydata, setCategorydata] = React.useState([]);
  const [author, setAuthor] = useState("");
  const [category, setCategory] = useState([]);
  const [text, setText] = useState("");
  const [notification_title, setNotification_title] = useState("");
  const [notification_desc, setNotification_desc] = useState("");
  const [selectedDate, setSelectedDate] = React.useState();
  const [selectedTime, setSelectedTime] = React.useState("");
  const [errorflag, setflag] = useState("");
  const [Image, setimage] = React.useState("");
  const [file, setProfileImage] = React.useState("");
  const [status, setStatus] = React.useState(false);
  const [iswaiting, setiswaiting] = useState(false);
  const [quotationType, setQuotationType] = React.useState("Publish");
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
  });
  const handleChange = (event) => {
    //console.log(event.target.checked);
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleChangeQuotationFor = (event) => {
    setQuotationType(event.target.value);
  };

  const handleDateChange = (date) => {
    //console.log(date.target.value);
    setSelectedDate(date.target.value);
  };
  const handleTimeChange = (time) => {
    //console.log(time.target.value);
    setSelectedTime(time.target.value);
  };
  const handleChangeAuthor = (event) => {
    //console.log(event.target.value, "authordata[0]?._id");
    setAuthor(event.target.value);
  };

  const handleChangeCategory = (value) => {
    //console.log(value, "value");
    let _catTag = [];
    value.map((x, i) => {
      _catTag.push({ title: x.title, _id: x._id });
    });
    setCategory(_catTag);
    //console.log(_catTag, "final tags");
  };
  //console.log(authordata[0]?._id, "authordataauthordata");
  const handletext = (event) => {
    //console.log(event.target.value);
    setText(event.target.value);
  };
  const handleNotificationTitle = (event) => {
    //console.log(event.target.value);
    setNotification_title(event.target.value);
  };
  const handleNotificationDesc = (event) => {
    //console.log(event.target.value);
    setNotification_desc(event.target.value);
  };

  const fileChangedHandler = (e) => {
    if (e.target.files.length) {
      setimage(e.target.files[0]);
      setProfileImage(URL.createObjectURL(e.target.files[0]));
    }
  };
  const handleback = () => {
    history.goBack();
  };
  const handlesubmit = async (event) => {
    const cat_array = [];
    category.map((x, i) => {
      //console.log(x._id);
      cat_array.push(x._id);
    });
    event.preventDefault();
    setiswaiting(true);
    let formData = new FormData(); //formdata object
    if (author) {
      formData.append("author", author);
    }
    formData.append("categories", cat_array);
    formData.append("title", text);
    formData.append("notification_title", notification_title);
    formData.append("notification_description", notification_desc);
    formData.append("quotation_type", quotationType);

    //formData.append("active_at", selectedDate + "T" + selectedTime);
    if (Image) {
      formData.append("large_image", Image);
    } else {
      enqueueSnackbar("please upload image", { variant: "error" });
      return;
    }

    if (Image) {
      formData.append("smaill_image", Image);
    }
    formData.append("notify_all", state.checkedA);
    formData.append("notify_now", state.checkedB);
    //time
    const time = moment(selectedDate + "T" + selectedTime).format(
      "YYYY/MM/DD HH:mm:ss ZZ"
    );

    if (quotationType == "Schedule") {
      formData.append("active_at", time == "Invalid date" ? "" : time);
    } else {
      // formData.append("active_at", time);
    }
    //console.log(...formData, "formDataformData");
    const quotes_add_resp = await add_quotes(formData);
    if (quotes_add_resp.code == 200) {
      enqueueSnackbar(quotes_add_resp.message, { variant: "success" });
      props.history.push("/QuotesList");
    } else {
      enqueueSnackbar(quotes_add_resp.message, { variant: "error" });
    }
    //console.log(quotes_add_resp?.media?._id, "id get");
    if (state.checkedB) {
      // await notify_all_quotes(quotes_add_resp.media._id );
    }
    //console.log(author, "authorauthorauthor");
  };

  //
  const author_get = async () => {
    const author_get_resp = await get_author();
    //console.log(author_get_resp._authors);
    setAuthordata(author_get_resp._authors);
  };
  const category_get = async () => {
    const category_get_resp = await get_category();
    //console.log(category_get_resp._categories);
    setCategorydata(category_get_resp._categories);
  };
  useEffect(() => {
    author_get();
    category_get();
  }, []);
  useEffect(() => {
    setAuthor(authordata[0]?._id);
  }, [authordata]);

  return (
    <>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <div className={classes.paper}>
          <ArrowBackIcon
            style={{
              cursor: "pointer",
              marginLeft: "-550px",
            }}
            onClick={handleback}
          />
          <Typography className={classes.txt} component="h1" variant="h5">
            Add New Quote
          </Typography>
          {errorflag && (
            <Alert className={classes.alert} severity="error">
              {errorflag}
            </Alert>
          )}
          <form className={classes.form} onSubmit={handlesubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  style={{ width: "100%" }}
                  required
                >
                  <InputLabel htmlFor="age-native-simple">Author</InputLabel>
                  <Select
                    native
                    onChange={handleChangeAuthor}
                    label="Author"
                    autoFocus
                    fullWidth
                    value={author}
                  >
                    {authordata.map((x, i) => {
                      return (
                        <>
                          <option key={i} value={x._id}>
                            {x.name}
                          </option>
                        </>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              {/* <Grid item xs={12}>
              <FormControl variant="outlined" style={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Author
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    fullWidth
                    onChange={handleChangeAuthor}
                    label="Author"
                    autoFocus
                  >
                    {authordata.map((x, i) => {
                      return <MenuItem value={x._id}>{x.name}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid> */}
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  id="tags-filled"
                  options={categorydata}
                  value={category}
                  getOptionLabel={(option) => option.title}
                  onChange={(event, value) => handleChangeCategory(value)}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option.title}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Categories *"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  // id="outlined-multiline-static"
                  // label="Pray Request Content"
                  multiline
                  rows="5"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  id="title"
                  value={text}
                  onChange={handletext}
                  label="Text"
                  name="text"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  value={notification_title}
                  onChange={handleNotificationTitle}
                  name="notification_title"
                  label="Notification Title"
                  type="text"
                  id="notification_title"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  // id="outlined-multiline-static"
                  // label="Pray Request Content"
                  multiline
                  rows="5"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  id="notification_desc"
                  value={notification_desc}
                  onChange={handleNotificationDesc}
                  label="Notification Description"
                  name="notification_desc"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <InputLabel htmlFor="age-native-simple">
                    Quotation Type
                  </InputLabel>
                  <Select
                    native
                    onChange={handleChangeQuotationFor}
                    label="Quotation Type"
                    autoFocus
                    fullWidth
                    value={quotationType}
                  >
                    <option value="Publish">Publish</option>
                    <option value="Schedule">Schedule</option>
                  </Select>
                </FormControl>
              </Grid>
              {quotationType == "Schedule" && (
                <>
                  <Grid item xs={6}>
                    <TextField
                      variant="outlined"
                      id="selectedDate"
                      type="date"
                      label="Date"
                      fullWidth
                      className={classes.textField}
                      value={selectedDate}
                      onChange={handleDateChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      variant="outlined"
                      id="selectedTime"
                      label="Time"
                      type="time"
                      fullWidth
                      className={classes.textField}
                      value={selectedTime}
                      onChange={handleTimeChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                {file ? (
                  <>
                    {" "}
                    <img className={classes.img} src={file} />{" "}
                  </>
                ) : null}
              </Grid>

              <Grid item xs={12}>
                <input
                  accept="image/x-png,image/jpeg"
                  hidden
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={fileChangedHandler}
                />
                <label htmlFor="contained-button-file">
                  <Button
                    component="span"
                    fullWidth
                    variant="outlined"
                    color="primary"
                  >
                    Upload Image
                  </Button>
                </label>
              </Grid>

              <Grid item xs={6}>
                Notify All :
                <FormControlLabel
                  style={{ marginLeft: 10 }}
                  control={
                    <Switch
                      color="primary"
                      checked={state.checkedA}
                      onChange={handleChange}
                      name="checkedA"
                    />
                  }
                />
              </Grid>

              <Grid item xs={6}>
                Notify Now :
                <FormControlLabel
                  style={{ marginLeft: 10 }}
                  control={
                    <Switch
                      color="primary"
                      checked={state.checkedB}
                      onChange={handleChange}
                      name="checkedB"
                    />
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Create Quote
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </>
  );
}
