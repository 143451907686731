import { invokeApi } from "../../bl_libs/invokeApi";

export const AddSettings = async (data) => {
  let requestObj = {
    path: "/api/general_setting/add_general_setting",
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  requestObj["postData"] = data;

  return invokeApi(requestObj);
};

export const GetSettings = async () => {
  let requestObj = {
    path: "/api/general_setting/get_general_setting",
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
