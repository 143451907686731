import React from "react";
import { filter } from "lodash";
// import { Icon } from "@iconify/react";
// import { sentenceCase } from "change-case";
import { useEffect, useState } from "react";
// import plusFill from "@iconify/icons-eva/plus-fill";
// import { Link as RouterLink } from "react-router-dom";
import { useHistory } from "react-router-dom";

// import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  Slide,
  CircularProgress,
} from "@mui/material";
// components
// import ViewCategory from "./ViewCategory";
// import Page from "../../components/Page";
// import Label from "../../components/Label";
// import Scrollbar from "../../components/Scrollbar";
import SearchNotFound from "../../components/SearchNotFound";
// import {
//   UserListHead,
//   UserListToolbar,
//   UserMoreMenu,
// } from "../../components/_dashboard/user";
import UserListHead from "../../components/Table/UserListHead";
import UserListToolbar from "../../components/Table/UserListToolbar";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

// import CustomPopover from "../../components/CustomPopover";
// import UpdateCategory from "../Category/UpdateCategory";

import { useSnackbar } from "notistack";
import CustomPopoverSection from "../../components/menuOption/CustomPopoverSection";
// import CategoryCofirmation from "../Category/CategoryConfirmation";
// import Loader from "../../components/Loader/Loader";

// import { _tag_list, _delete_tag } from "../../DAL/tags/tags";
// import AddTags from "./AddTags";
// import UpdateTag from "./UpdateTag";

import { s3baseUrl } from "../../config/config";
import {
  delete_prayer,
  Prayer_list,
  prayer_search,
} from "../../DAL/prayer/prayer";
import {
  delete_quotes,
  quotes_list,
  quotes_search,
} from "../../DAL/quotes/quotes";
import moment from "moment";
import {
  deletePlaylistApi,
  playlistList,
} from "../../DAL/Meditation/meditation";
import {
  allTracksListApi,
  deleteTrackApi,
} from "../../DAL/Meditation/allTracks";
import UserListToolbars from "../../components/Table/UserListToolbars";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "#", label: "#", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  { id: "image", label: "Image", alignRight: false },
  { id: "playList", label: "Play List", alignRight: false },
  { id: "description", label: "Description", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "action", label: "Action", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (row) => row.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function AllTracksList() {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [page, setPage] = useState(0);
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalPages, setTotalPages] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);
  const [DrawerOpen, setDrawerOpen] = useState(false);
  const [viewDrawerOpen, setViewDrawerOpen] = useState(false);
  const [addDrawerOpen, setAddDrawerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [selectedrow, setrowdata] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [isdata, setisdata] = React.useState(false);
  const [Id, setId] = React.useState();
  const [filterNameStatus, setFilterNameStatus] = React.useState(false);

  const get_authors = async () => {
    let postData = {
      search: filterName,
    };
    setIsLoading(true);
    const res = await allTracksListApi(page, rowsPerPage, filterName);

    //console.log(res, "all tracks result");
    if (res.code === 200) {
      setIsLoading(false);
      //console.log(res, "result of Quotes");
      const tags = res?.data.map((data, index) => {
        return {
          ...data,
          id: data._id,
          name: data.title,
          image: data?.image?.thumbnail_1,
          description: data.description,
          status: data.status,
          playlist: data?.play_list?.name,
          count: index + 1 + rowsPerPage * page,
        };
      });

      setData(tags);
      setTotalCount(res.total_tracks);
      setIsLoading(false);
    }
  };
  const handleSearchName = () => {
    get_authors();
    setFilterNameStatus(true);
  };
  const deleteuser = async () => {
    setIsLoading(true);
    setOpen(false);
    setData([]);
    setisdata(false);
    const delete_author_resp = await deleteTrackApi(selectedRow._id);
    if (delete_author_resp.code == 200) {
      //console.log("deleted");
      get_authors(page);
    } else {
      //console.log("not deleted");
      setIsLoading(false);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    setPage(0);
  };

  const emptyRows =
    // page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;
    page > 0 && 0;

  const filteredUsers = applySortFilter(
    data,
    getComparator(order, orderBy)
    // filterName
  );
  // //console.log(filteredUsers, "filteredUsers");

  const isUserNotFound = filteredUsers.length === 0;

  const handleDeleteClick = (row) => {
    //console.log(row, "rowwwwwwwwwwww");
    setSelectedRow(row);
    setOpen(true);
  };
  const Addquotes = () => {
    history.push("/QuotesList/AddQuotes");
  };

  const handleUpdate = (row) => {
    setSelectedRow(row);
    handleOpenChangeTag();
  };

  const handleEdit = (row) => {
    //console.log(row, "row");
    // navigate(`/edit_author/${row._id}`);
    // navigate(`/member/goal-statement-Question-reply/${params.id}`, {
    //   state: value,
    // });
  };
  const handleOpenViewDrawer = (row) => {
    setViewDrawerOpen(true);
  };

  //   const handleCloseViewDrawer = () => {
  //     // setSelectedRow(row);
  //     setViewDrawerOpen(false);
  //   };

  const handleOpenChangeTag = () => {
    setDrawerOpen(true);
  };

  const AddTrack = () => {
    history.push("/allTracks/add-track");
  };
  const handleNavigate = (value) => {
    //console.log(value, "value of the track");
    history.push(`/Playlist/trackList/${value._id}`);
  };
  const handleNavigateEdit = (value) => {
    //console.log(value, "value of the track");
    // history.push(`/trackList/${params.id}/edit-track/${value._id}`);
    history.push({
      pathname: `/allTracks/edit-track/${value._id}`,
      state: value,
    });
  };
  const handleAdd = (value) => {
    //console.log(value, "value of the track");
    history.push(`/Playlist/add-playlist`);
  };

  const handleClickOpen = (rowdata) => {
    //console.log(rowdata, "okokoko");
    setrowdata(rowdata);
    setOpen(true);
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleNavigateEdit,
    },
    {
      label: "Delete",
      icon: "ep:delete",
      handleClick: handleDeleteClick,
    },
  ];

  useEffect(() => {
    get_authors();
  }, [page, rowsPerPage]);
  // useEffect(() => {
  //   get_authors();
  // }, []);

  // if (isLoading) {
  //   return <CircularProgress className="Loader" />;
  // }
  //console.log(filterName, "filterName");
  return (
    <>
      <>
        {/* <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton> */}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            All Tracks
          </Typography>
          <Button
            className="small-contained-button"
            onClick={AddTrack}
            variant="contained"
            color="primary"
            // startIcon={<Icon icon={plusFill} />}
          >
            Add Track
          </Button>
        </Stack>

        <Card>
          <UserListToolbars
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            onSearch={handleSearchName}
          />

          <TableContainer>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={data.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsers
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const {
                      _id,
                      name,
                      content,
                      prayerCount,
                      status,
                      createdAt,
                      count,
                      description,
                      author,
                      image,
                      playlist,
                    } = row;

                    return (
                      <TableRow hover key={_id}>
                        <TableCell>{page == 0 ? index + 1 : count}</TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">{name}</Typography>
                        </TableCell>
                        <TableCell className="pr-0" align="left">
                          {image && (
                            <img
                              src={s3baseUrl + image}
                              height={50}
                              width={50}
                            ></img>
                          )}
                        </TableCell>
                        <TableCell>
                          <Typography className="pl-2" variant="subtitle2">
                            {playlist ? playlist : "N/A"}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>
                            {description
                              ? description.substring(0, 50) + "..."
                              : "N/A"}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography className="pl-2" variant="subtitle2">
                            {status == false ? "Inactive" : "Active"}
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <CustomPopoverSection
                            menu={MENU_OPTIONS}
                            data={row}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={4} />
                  </TableRow>
                )}
              </TableBody>
              {isLoading ? (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                        <SearchNotFound />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )
              )}
            </Table>
          </TableContainer>

          {/* <CategoryCofirmation
            open={openDelete}
            setOpen={setOpenDelete}
            title={"Are you sure you want to delete this Tag?"}
            handleAgree={handleDelete}
          /> */}

          <TablePagination
            rowsPerPageOptions={[10, 50, 100, 150]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </>
      {/* <UpdateTag
        OpenDrawer={DrawerOpen}
        onOpenDrawer={handleOpenChangeTag}
        onCloseDrawer={handleCloseChangeTag}
        selectedRow={selectedRow}
        get_tag_list={get_tag_list}
      /> */}
      {/* <AddTags
        AddOpenDrawer={addDrawerOpen}
        OpenDrawer={handleOpenAddTags}
        CloseDrawer={handleCloseAddTags}
        get_tag_list={get_tag_list}
      /> */}
      {/* <ViewCategory
        OpenDrawer={viewDrawerOpen}
        onOpenDrawer={handleOpenViewDrawer}
        onCloseDrawer={handleCloseViewDrawer}
        selectedRow={selectedRow}
        get_category_list={get_category_list}
      /> */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{`Are you sure you want to Delete`}</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Disagree
          </Button>
          <Button onClick={deleteuser} color="primary">
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
