import { invokeApi } from "../../bl_libs/invokeApi";

export const Category_list = async (page, limit, search) => {
  let requestObj = {
    path: "admin/category/list",
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    queryParams: {
      page: page,
      limit: limit,
      search: search,
    },
  };
  return invokeApi(requestObj);
};

export const Category_Detail_list = async (page, id) => {
  let requestObj = {
    path: `admin/v1/media_list_by_category/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    queryParams: {
      page: page,
    },
  };
  return invokeApi(requestObj);
};

export const add_category = async (data) => {
  let requestObj = {
    path: "admin/category/create",
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const delete_category = async (_id) => {
  let requestObj = {
    path: "admin/category/delete",
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    queryParams: {
      category_id: _id,
    },
  };
  return invokeApi(requestObj);
};
export const category_search = async (search_text) => {
  let requestObj = {
    path: "admin/category/search",
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    queryParams: {
      title: search_text,
    },
  };
  return invokeApi(requestObj);
};
export const category_detail_search = async (search_text, id) => {
  let requestObj = {
    path: `admin/v1/media_list_by_category_search/${id}?title=${search_text}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const edit_category = async (data, id) => {
  let requestObj = {
    path: `admin/category/update/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const get_category_detail = async (id) => {
  let requestObj = {
    path: `admin/category/get`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    queryParams: {
      category_id: id,
    },
  };
  return invokeApi(requestObj);
};
