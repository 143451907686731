import { invokeApi } from "../../bl_libs/invokeApi";

export const user_list = (page, limit, search) => {
  let requestObj = {
    path: `admin/v1/user_list?page=${page}&limit=${limit}&search=${search}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const user_reports = (page, limit, search) => {
  let requestObj = {
    // path: `admin/v1/all_reports?page=${page}&limit=${limit}&search=${search}`,
    path: `admin/v1/all_reports`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    queryParams: {
      page: page,
      limit: limit,
      search: search,
    },
  };
  return invokeApi(requestObj);
};

export const user_report_reply = (id) => {
  let requestObj = {
    path: `admin/get_a_report/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const user_report_editreply = (id, data) => {
  let requestObj = {
    path: `admin/reply_to_report/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const user_search = async (search_text, page) => {
  let requestObj = {
    path: `admin/v1/user_search?page=${page}&full_name=${search_text}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const user_reports_search = (search_text, page) => {
  let requestObj = {
    path: `admin/v1/search_report?page=${page}&report=${search_text}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
