import { invokeApi } from "../../bl_libs/invokeApi";

export const AdminLogin = async (data) => {
  let requestObj = {
    path: "user/login",
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  requestObj["postData"] = data;

  return invokeApi(requestObj);
};

export const AdminLogout = async (data) => {
  let requestObj = {
    path: "user/logout",
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  requestObj["postData"] = data;

  return invokeApi(requestObj);
};
