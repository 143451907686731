import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { useHistory } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { s3baseUrl } from "../../../config/config";
import { withStyles } from '@material-ui/core/styles';
import { purple } from '@material-ui/core/colors';
import {
  get_category_detail,
  edit_category,
} from "../../../DAL/Category/Category";
import { useSnackbar } from "notistack";

const PurpleSwitch = withStyles({
    switchBase: {
      color: purple[300],
      '&$checked': {
        color: purple[500],
      },
      '&$checked + $track': {
        backgroundColor: purple[500],
      },
    },
    checked: {},
    track: {},
  })(Switch);
const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: "#37CD77",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    marginTop: "3%",
    color: "white",

    fontSize: "15px",
  },
  txt: {
    color: "black",
    marginTop: "4%",
    marginBottom:20
  },
  alert: {
    width: "100%",
    marginBottom: 20,
  },
  avtr: {
    width: 80,
    height: 80,
    cursor: "pointer",
    backgroundColor: "#37CD77",
    color: "white",
  },
  blog_img: {
    marginTop: 10,
    width: 550,
    height: 300,
  },
}));

export default function EditCategory(props) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [title, set_title] = useState("");
  const [orderno, setOrderno] = useState("");
  const [errorflag, setflag] = useState("");
  const [Image, setimage] = React.useState("");
  const [temp_image, setTempImage] = React.useState("");
  const [file, setProfileImage] = React.useState("");
  const [is_load_data, setLoadData] = React.useState(false);
  const [state, setState] = React.useState({
      checkedA:true,
  });
  const [iswaiting, setiswaiting] = useState(false);

  
  const handletitle = (event) => {
    set_title(event.target.value);
  };
  const handleorderno = (event) => {
    setOrderno(event.target.value);
  };
  const handleChange = (event) => {
   console.log(event.target.checked);
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const fileChangedHandler = (e) => {
    if (e.target.files.length) {
      setimage(e.target.files[0]);
      setProfileImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleback = () => {
    history.goBack();
  };
  const handlesubmit = async (event) => {
    event.preventDefault();
    setiswaiting(true);
    let formData = new FormData(); //formdata object
    formData.append("title", title);
    formData.append("is_active", state.checkedA);
    formData.append("order_number", orderno);
    if (Image) {
      formData.append("image", Image);
    }

    const category_edit_resp = await edit_category(formData, props.match.params.id);
    console.log(category_edit_resp, "RESPONSE");
    if (category_edit_resp.code == 200) {
      props.history.push("/Categories");
    } else {
      enqueueSnackbar(category_edit_resp.message, { variant: "error" });
     }
  };

  useEffect(async () => {
    const category_detail = await get_category_detail(props.match.params.id);
    console.log(category_detail);
    set_title(category_detail.category.title);
    setOrderno(category_detail.category.order_number);
    setState({ ...state, checkedA: category_detail.category.is_active});
    setTempImage(category_detail.category.cat_img);
    setLoadData(true);
  }, []);
  return (
    <>
      {is_load_data == false ? (
        <CircularProgress style={{ marginTop: "15%", marginLeft: "45%" }} />
      ) : (
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <div className={classes.paper}>
            <ArrowBackIcon
              style={{
                cursor: "pointer",
                marginLeft: "-550px",
              }}
              onClick={handleback}
            />
            <Typography
              className={classes.txt}
              component="h1"
              variant="h5"
            >Edit Category</Typography>
            {errorflag && (
              <Alert className={classes.alert} severity="error">
                {errorflag}
              </Alert>
            )}
            <form className={classes.form} onSubmit={handlesubmit}>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <input
                  accept="image/x-png,image/jpeg"
                  hidden
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={fileChangedHandler}
                />
                <label htmlFor="contained-button-file">
                  <Avatar
                    alt="Remy Sharp"
                    className={classes.avtr}
                    src={file !== "" ? file : s3baseUrl + temp_image}
                  >
                    <AddAPhotoIcon />
                  </Avatar>
                  <span style={{ marginLeft: 8 }}>200 X 200</span>
                </label>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="title"
                    value={title}
                    onChange={handletitle}
                    label="Title"
                    name="title"
                    autoFocus
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    type="number"
                    InputProps={{
                        inputProps: { 
                             min: 1 
                        }
                    }}
                    fullWidth
                    id="orderno"
                    value={orderno}
                    onChange={handleorderno}
                    label="Order Number"
                    name="orderno"
                    autoFocus
                  />
                </Grid>

              <Grid item xs={6}>
                Status :
                <FormControlLabel
                style={{marginLeft:10}}
                  control={
                    <Switch
                      color="primary"
                      checked={state.checkedA}
                      onChange={handleChange}
                      name="checkedA"
                    />
                  }
                />
              </Grid>

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Edit Category
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      )}
    </>
  );
}
