import React from "react";
import { useEffect } from "react";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import { withRouter } from "react-router";
import { color_palette } from "../../../../theme/theme";
import SettingsIcon from "@material-ui/icons/Settings";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import CategoryIcon from "@material-ui/icons/Category";
import { makeStyles } from "@material-ui/core/styles";
import ListSubheader from "@material-ui/core/ListSubheader";
import Collapse from "@material-ui/core/Collapse";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import SendIcon from "@material-ui/icons/Send";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import StarBorder from "@material-ui/icons/StarBorder";
import PersonIcon from "@material-ui/icons/Person";
import GroupIcon from "@material-ui/icons/Group";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));
function SideMenu(props) {
  const { selectedIndex, handleListItemClick } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openquotes, setOpenquotes] = React.useState(false);
  const [openMeditation, setOpenMeditation] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  const handleClickquotes = () => {
    setOpenquotes(!openquotes);
  };
  const handleClickMeditation = () => {
    setOpenMeditation(!openMeditation);
  };
  useEffect(() => {
    const currentPath = props.history.location.pathname;
    if (currentPath.search("dashboard") > 0) {
      handleListItemClick("dashboard");
    } else if (currentPath.search("Authors") > 0) {
      handleListItemClick("Authors");
    } else if (currentPath.search("Users") > 0) {
      handleListItemClick("Users");
    } else if (currentPath.search("User_report") > 0) {
      handleListItemClick("User_report");
    } else if (currentPath.search("Categories") > 0) {
      handleListItemClick("Categories");
    } else if (currentPath.search("PrayerRequest") > 0) {
      handleListItemClick("PrayerRequest");
    } else if (currentPath.search("NotifyUpdates") > 0) {
      handleListItemClick("NotifyUpdates");
    } else if (currentPath.search("QuotesList") > 0) {
      handleListItemClick("QuotesList");
    } else if (currentPath.search("ScheduledQuotes") > 0) {
      handleListItemClick("ScheduledQuotes");
    } else if (currentPath.search("PendingQuotes") > 0) {
      handleListItemClick("PendingQuotes");
    } else if (currentPath.search("Playlist") > 0) {
      handleListItemClick("Playlist");
    } else if (currentPath.search("allTracks") > 0) {
      handleListItemClick("allTracks");
    } else {
      handleListItemClick("");
    }
  }, [selectedIndex, props.history.location.pathname]);
  return (
    <>
      <List component="nav" aria-label="main mailbox folders">
        {/* ------------------------------------------------------ Dashboard */}
        <ListItem
          style={
            selectedIndex === "dashboard"
              ? { backgroundColor: color_palette.primary, color: "white" }
              : {}
          }
          selected={selectedIndex === "dashboard"}
          onClick={(event) => {
            handleListItemClick("dashboard");
            props.history.push("/dashboard");
          }}
          button
        >
          <ListItemIcon>
            <DashboardIcon
              style={selectedIndex === "dashboard" ? { color: "white" } : {}}
            />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>

        {/* ------------------------------------------------------ Authors */}

        <ListItem
          style={
            selectedIndex === "Authors"
              ? { backgroundColor: color_palette.primary, color: "white" }
              : {}
          }
          selected={selectedIndex === "Authors"}
          onClick={(event) => {
            handleListItemClick("Authors");
            props.history.push("/Authors");
          }}
          button
        >
          <ListItemIcon>
            <PersonIcon
              style={selectedIndex === "Authors" ? { color: "white" } : {}}
            />
          </ListItemIcon>
          <ListItemText primary="Authors" />
        </ListItem>

        {/* ------------------------------------------------------ Users  */}
        <ListItem button onClick={handleClick}>
          <ListItemIcon>
            <GroupIcon
              style={selectedIndex === "Users" ? { color: "#22787E" } : {}}
            />
          </ListItemIcon>
          <ListItemText primary="Users" />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List>
            {/* ------------------------------------------------------ Users */}

            <ListItem
              button
              className={classes.nested}
              style={
                selectedIndex === "Users"
                  ? { backgroundColor: color_palette.primary, color: "white" }
                  : {}
              }
              selected={selectedIndex === "Users"}
              onClick={(event) => {
                handleListItemClick("Users");
                props.history.push("/Users");
              }}
              button
            >
              <ListItemIcon>
                <GroupIcon
                  style={selectedIndex === "Users" ? { color: "white" } : {}}
                />
              </ListItemIcon>
              <ListItemText primary="Users" />
            </ListItem>

            {/* ------------------------------------------------------ Users Report */}

            <ListItem
              button
              className={classes.nested}
              style={
                selectedIndex === "User_report"
                  ? { backgroundColor: color_palette.primary, color: "white" }
                  : {}
              }
              selected={selectedIndex === "User_report"}
              onClick={(event) => {
                handleListItemClick("User_report");
                props.history.push("/User_report");
              }}
              button
            >
              <ListItemIcon>
                <ReportProblemIcon
                  style={
                    selectedIndex === "User_report" ? { color: "white" } : {}
                  }
                />
              </ListItemIcon>
              <ListItemText primary="Users Report" />
            </ListItem>
          </List>
        </Collapse>

        {/* ------------------------------------------------------ Categories  */}
        <ListItem
          style={
            selectedIndex === "Categories"
              ? { backgroundColor: color_palette.primary, color: "white" }
              : {}
          }
          selected={selectedIndex === "Categories"}
          onClick={(event) => {
            handleListItemClick("Categories");
            props.history.push("/Categories");
          }}
          button
        >
          <ListItemIcon>
            <CategoryIcon
              style={selectedIndex === "Categories" ? { color: "white" } : {}}
            />
          </ListItemIcon>
          <ListItemText primary="Categories" />
        </ListItem>
        {/* ------------------------------------------------------ Prayer */}
        <ListItem
          style={
            selectedIndex === "PrayerRequest"
              ? { backgroundColor: color_palette.primary, color: "white" }
              : {}
          }
          selected={selectedIndex === "PrayerRequest"}
          onClick={(event) => {
            handleListItemClick("PrayerRequest");
            props.history.push("/PrayerRequest");
          }}
          button
        >
          <ListItemIcon>
            <VerifiedUserIcon
              style={
                selectedIndex === "PrayerRequest" ? { color: "white" } : {}
              }
            />
          </ListItemIcon>
          <ListItemText primary="Prayer Request" />
        </ListItem>
        {/* ------------------------------------------------------ Notify for new Updates */}
        <ListItem
          style={
            selectedIndex === "NotifyUpdates"
              ? { backgroundColor: color_palette.primary, color: "white" }
              : {}
          }
          selected={selectedIndex === "NotifyUpdates"}
          onClick={(event) => {
            handleListItemClick("NotifyUpdates");
            props.history.push("/NotifyUpdates");
          }}
          button
        >
          <ListItemIcon>
            <NotificationsActiveIcon
              style={
                selectedIndex === "NotifyUpdates" ? { color: "white" } : {}
              }
            />
          </ListItemIcon>
          <ListItemText primary="Notify Updates" />
        </ListItem>

        {/* ------------------------------------------------------ Quotes  */}
        <ListItem button onClick={handleClickquotes}>
          <ListItemIcon>
            <FormatQuoteIcon
              style={selectedIndex === "QuotesList" ? { color: "#22787E" } : {}}
            />
          </ListItemIcon>
          <ListItemText primary="Quotes" />
          {openquotes ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={openquotes} timeout="auto" unmountOnExit>
          <List>
            {/* ------------------------------------------------------  Quotes  */}

            <ListItem
              button
              className={classes.nested}
              style={
                selectedIndex === "QuotesList"
                  ? { backgroundColor: color_palette.primary, color: "white" }
                  : {}
              }
              selected={selectedIndex === "QuotesList"}
              onClick={(event) => {
                handleListItemClick("QuotesList");
                props.history.push("/QuotesList");
              }}
              button
            >
              <ListItemIcon>
                <FormatQuoteIcon
                  style={
                    selectedIndex === "QuotesList" ? { color: "white" } : {}
                  }
                />
              </ListItemIcon>
              <ListItemText primary="Quotes" />
            </ListItem>

            {/* ------------------------------------------------------  Scheduled Quotes  */}

            <ListItem
              button
              className={classes.nested}
              style={
                selectedIndex === "ScheduledQuotes"
                  ? { backgroundColor: color_palette.primary, color: "white" }
                  : {}
              }
              selected={selectedIndex === "ScheduledQuotes"}
              onClick={(event) => {
                handleListItemClick("ScheduledQuotes");
                props.history.push("/ScheduledQuotes");
              }}
              button
            >
              <ListItemIcon>
                <FormatQuoteIcon
                  style={
                    selectedIndex === "ScheduledQuotes"
                      ? { color: "white" }
                      : {}
                  }
                />
              </ListItemIcon>
              <ListItemText primary="Scheduled Quotes" />
            </ListItem>

            {/* ------------------------------------------------------  Pending Quotes  */}

            <ListItem
              button
              className={classes.nested}
              style={
                selectedIndex === "PendingQuotes"
                  ? { backgroundColor: color_palette.primary, color: "white" }
                  : {}
              }
              selected={selectedIndex === "PendingQuotes"}
              onClick={(event) => {
                handleListItemClick("PendingQuotes");
                props.history.push("/PendingQuotes");
              }}
              button
            >
              <ListItemIcon>
                <FormatQuoteIcon
                  style={
                    selectedIndex === "PendingQuotes" ? { color: "white" } : {}
                  }
                />
              </ListItemIcon>
              <ListItemText primary="Pending Quotes" />
            </ListItem>
          </List>
        </Collapse>
        {/* ------------------------------------------------------ Meditation  */}
        <ListItem button onClick={handleClickMeditation}>
          <ListItemIcon>
            <FormatQuoteIcon
              style={selectedIndex === "QuotesList" ? { color: "#22787E" } : {}}
            />
          </ListItemIcon>
          <ListItemText primary="Meditation" />
          {openMeditation ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={openMeditation} timeout="auto" unmountOnExit>
          <List>
            {/* ------------------------------------------------------  Playlist  */}

            <ListItem
              button
              className={classes.nested}
              style={
                selectedIndex === "Playlist"
                  ? { backgroundColor: color_palette.primary, color: "white" }
                  : {}
              }
              selected={selectedIndex === "Playlist"}
              onClick={(event) => {
                handleListItemClick("Playlist");
                props.history.push("/Playlist");
              }}
              button
            >
              <ListItemIcon>
                <FormatQuoteIcon
                  style={selectedIndex === "Playlist" ? { color: "white" } : {}}
                />
              </ListItemIcon>
              <ListItemText primary="Playlist" />
            </ListItem>

            {/* ------------------------------------------------------  Scheduled Quotes  */}

            <ListItem
              button
              className={classes.nested}
              style={
                selectedIndex === "allTracks"
                  ? { backgroundColor: color_palette.primary, color: "white" }
                  : {}
              }
              selected={selectedIndex === "allTracks"}
              onClick={(event) => {
                handleListItemClick("allTracks");
                props.history.push("/allTracks");
              }}
              button
            >
              <ListItemIcon>
                <FormatQuoteIcon
                  style={
                    selectedIndex === "allTracks" ? { color: "white" } : {}
                  }
                />
              </ListItemIcon>
              <ListItemText primary="All Tracks" />
            </ListItem>

            {/* ------------------------------------------------------  Pending Quotes  */}
          </List>
        </Collapse>
      </List>
    </>
  );
}

export default withRouter(SideMenu);
