import { invokeApi } from "../../bl_libs/invokeApi";

export const quotes_list = async (search, page, limit) => {
  let requestObj = {
    // path: "admin/media_images/list",
    path: `admin/media_images/list?search=${search}&page=${page}&limit=${limit}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const scheduled_quotes_list = async (search, page, limit) => {
  let requestObj = {
    // path: `admin/media_images/scheduled_list`,
    path: `admin/media_images/scheduled_list?search=${search}&page=${page}&limit=${limit}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const pending_quotes_list = async (search, page, limit) => {
  let requestObj = {
    // path: `admin/media_images/pending_list`,
    path: `admin/media_images/pending_list?search=${search}&page=${page}&limit=${limit}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_author = async (data) => {
  let requestObj = {
    path: "admin/author/list_new",
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const get_category = async (data) => {
  let requestObj = {
    path: "admin/category/list_new",
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const add_quotes = async (data) => {
  let requestObj = {
    path: "admin/media_images/create_quotation",
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const notify_all_quotes = async (_id) => {
  let requestObj = {
    path: "admin/notify_now",
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    queryParams: {
      media_id: _id,
    },
  };
  return invokeApi(requestObj);
};

export const delete_quotes = async (_id) => {
  let requestObj = {
    path: "admin/media_images/delete",
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    queryParams: {
      media_id: _id,
    },
  };
  return invokeApi(requestObj);
};

export const quotes_search = async (search_text, id, from) => {
  let requestObj = {
    path: "admin/v1/search_media",
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },

    queryParams: {
      description: search_text,
      from: from,
      id: id,
    },
  };
  return invokeApi(requestObj);
};

export const scheduled_quotes_search = async (search_text, id, from) => {
  let requestObj = {
    path: "admin/v1/search_media",
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },

    queryParams: {
      description: search_text,
      from: from,
      id: id,
    },
  };
  return invokeApi(requestObj);
};

export const pending_quotes_search = async (search_text, id, from) => {
  let requestObj = {
    path: "admin/v1/search_media",
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },

    queryParams: {
      description: search_text,
      from: from,
      id: id,
    },
  };
  return invokeApi(requestObj);
};

export const get_quotes_detail = async (id) => {
  let requestObj = {
    path: `admin/media_images/get?media_id=${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const update_quote = async (data, id) => {
  let requestObj = {
    path: `admin/media_images/update/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
