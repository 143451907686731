import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import DeleteIcon from "@material-ui/icons/Delete";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import TablePagination from "@material-ui/core/TablePagination";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import { color_palette } from "../../../theme/theme";
import { useSnackbar } from "notistack";
//import Details from "../ProfileDetail/Detail";
import {
  user_reports,
  user_reports_search,
  user_report_reply,
  user_report_editreply,
} from "../../../DAL/User/UserListing";
import EditIcon from "@material-ui/icons/Edit";
import { default_image } from "../../../assets";
import { s3baseUrl } from "../../../config/config";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Box from "@material-ui/core/Box";

var moment = require("moment-timezone");

///-------dialog-transition-------///
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
///-----------------CSS-Classes---------------///
const useStyles = makeStyles((theme) => ({
  hed: {
    fontWeight: "bold",
  },
  clmwdth: {
    width: "10px",
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  rightBtn: {
    marginRight: 5,
  },
  btnadd: {
    float: "right",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  btnapprove: {
    color: "white",
    fontSize: "15px",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    height: "90%",
    // overflowY: "scroll",
  },
}));

function Users_report_old(props) {
  const classes = useStyles();
  const tableRef = React.useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [opendialog, setOpendialog] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [del, setDel] = React.useState(false);
  const [addQuestion, setAddQuestion] = React.useState(false);
  const [selectedrow, setrowdata] = React.useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isdata, setisdata] = React.useState(false);
  const [selectedBtn, setSelectedBtn] = useState("0");
  const [page, setPage] = React.useState(1);
  const [totalPage, settotalpage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [data, setData] = useState([]);
  const [openDetails, setOpenDetails] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [reply, setReply] = React.useState("");
  const [report, setReport] = React.useState("");
  const [reply_id, setReply_id] = React.useState("");

  const handleClickOpendialog = (rowdata) => {
    setOpendialog(true);
    getReply(rowdata);
    setReply_id(rowdata._id);
  };

  const handleClosedialog = () => {
    setOpendialog(false);
  };

  const handleOpenDetails = (rowdata) => {
    setOpenDetails(true);
    setrowdata(rowdata);
  };

  const handleCloseDetails = () => {
    setOpenDetails(false);
  };
  ///---------------------Functions-------------------///
  const handleClickOpen = (rowdata) => {
    setrowdata(rowdata);
    setOpen(true);
  };

  const handleStage = (stage) => {
    setSelectedBtn(stage);
  };
  const handlereply = (e) => {
    setReply(e.target.value);
  };

  const EditReply = async (e) => {
    e.preventDefault();
    const data = {
      reply: reply,
    };

    const report_edit_reply_resp = await user_report_editreply(reply_id, data);
    console.log(report_edit_reply_resp, "RESPONSE");
    if (report_edit_reply_resp.code == 200) {
      handleClosedialog();
      FetchListing();
    } else {
      enqueueSnackbar(report_edit_reply_resp.message, { variant: "error" });
    }
  };

  const getReply = async (rowdata) => {
    const result = await user_report_reply(rowdata._id);
    if (result.code === 200) {
      console.log(result.report.report);
      setReport(result.report.report);
      setReply(result.report.reply);
      // settotalpage(result.totat_pages);
      // setisdata(true);
      // setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleClose = () => {
    setDel(false);
    setAddQuestion(false);
    setOpen(false);
  };
  const hanldeSearchChange = async (e) => {
    setSearch(e.target.value);
    setData([]);
    setisdata(true);
    const data_search = await (e.target.value, page);
    console.log(data_search, "DATA SEARCH");
    if (data_search.code == 200) {
      if (data_search.reports.length != 0) {
        setData(data_search.reports);
        settotalpage(data_search.totat_pages);
      }
      setisdata(true);
    }
  };
  const handleChangePage = (event, newPage) => {
    setData([]);
    setisdata(false);
    setPage(newPage);
  };
  const handleAgreeClose = async () => {};
  const handleChangeRowsPerPage = (event) => {
    tableRef.current.dataManager.changePageSize(parseInt(event.target.value));
    setData([]);
    setisdata(false);
    setRowsPerPage(parseInt(event.target.value));
    setPage(1);
  };
  const FetchListing = async () => {
    const result = await user_reports(page);
    if (result.code === 200) {
      setData(result.reports);
      settotalpage(result.totat_pages);
      setisdata(true);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    FetchListing(page);
  }, [rowsPerPage, page]);

  //console.log(data,"data-array")

  const [columns, setColumns] = useState([
    {
      title: <span className={classes.hed}>#</span>,
      width: 150,
      render: (rowData) => <>{rowData.tableData.id + 1}</>,
    },
    {
      title: <span className={classes.hed}> User Name</span>,
      render: (rowData) =>
        rowData.user.full_name ? (
          <>
            <div style={{ minWidth: 100 }}>{rowData.user.full_name}</div>
          </>
        ) : (
          <>
            <div style={{ minWidth: 100 }}>N / A</div>
          </>
        ),
      sorting: false,
    },
    {
      title: <span className={classes.hed}>Reply Date</span>,
      render: (rowData) => (
        <>{moment(rowData.reply_date).format("YYYY-MM-DD")}</>
      ),
      sorting: true,
    },
    {
      title: <span className={classes.hed}>Image</span>,
      render: (rowData) => (
        <img
          src={
            rowData.feed_img == ""
              ? default_image
              : s3baseUrl + rowData.feed_img
          }
          style={{ width: 50, height: 50 }}
        ></img>
      ),
      sorting: false,
    },

    // {
    //   title: <span className={classes.hed}>Status</span>,

    //   render: (rowData) =>
    //     rowData.status ? (
    //       <>
    //         <div style={{ minWidth: 100 }}>
    //           <Radio
    //             checked
    //             style={{ color: color_palette.primary, marginLeft: -15 }}
    //           />
    //           Active
    //         </div>
    //       </>
    //     ) : (
    //       <>
    //         <div style={{ minWidth: 100 }}>
    //           <Radio
    //             checked={true}
    //             disabled
    //             color={color_palette.primary}
    //             style={{ marginLeft: -15 }}
    //           />
    //           Inactive
    //         </div>
    //       </>
    //     ),
    //   sorting: false,
    // },
    {
      title: <span className={classes.hed}> Reply</span>,
      render: (rowdata) => (
        <>
          <div>
            <Button
              onClick={() => handleClickOpendialog(rowdata)}
              className={classes.btnapprove}
              variant="contained"
              color="primary"
            >
              {rowdata.reply == "" ? "Reply" : " Edit Reply"}
            </Button>
          </div>
        </>
      ),

      sorting: false,
    },
  ]);

  ///-------------styling-icons---------------------///

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => (
      <Edit className={classes.Icons} {...props} ref={ref} />
    )),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    DeleteIcon: forwardRef((props, ref) => <DeleteIcon {...props} ref={ref} />),
  };

  return (
    <>
      <MaterialTable
        tableRef={tableRef}
        onSearchChange={(e) => console.log("search changed: " + e)}
        style={{ marginTop: "50px" }}
        title="Users report"
        icons={tableIcons}
        columns={columns.map((c) => ({ ...c, tableData: undefined }))}
        components={{
          Pagination: (props) => (
            <TablePagination
              component="div"
              count={totalPage}
              rowsPerPageOptions={[10]}
              page={page - 1}
              onChangePage={handleChangePage}
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          ),
          Toolbar: (props) => (
            <div>
              <Box display="flex" p={1}>
                <Box p={1} flexGrow={1}>
                  <h3>Users report</h3>
                </Box>
                <Box p={1}>
                  <TextField
                    id="input-with-icon-textfield"
                    value={search}
                    focused
                    autoFocus={true}
                    onChange={hanldeSearchChange}
                    //label="Search"
                    placeholder="Search"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          style={{ float: "right" }}
                        >
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Box>
            </div>
          ),
        }}
        localization={{
          body: {
            emptyDataSourceMessage: (
              <div style={{ padding: 20 }}>
                {isdata == false ? (
                  <CircularProgress />
                ) : (
                  <h5>No data found to display</h5>
                )}
              </div>
            ),
          },
        }}
        data={data}
        // actions={[
        //   {
        //     icon: () => <DeleteIcon />,
        //     tooltip: "Delete Report",
        //     onClick: (event, rowData) => handleClickOpen(rowData),
        //   },
        //   {
        //     icon: () => <EditIcon />,
        //     tooltip: "Edit Report",
        //      onClick: (event, rowData) =>
        //        props.history.push({
        //          pathname: `/edit_author/${rowData._id}`,
        //          user: rowData,
        //        }),
        //   },
        // ]}
        options={{
          actionsColumnIndex: -1,
          search: true,
          pageSize: rowsPerPage,
          emptyRowsWhenPaging: false,
          headerStyle: {
            backgroundColor: "#37CD77",
            color: "white",
            fontWeight: "bold",
          },
        }}
      />
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{`Are you sure you want to Delete "${selectedrow.title}" ?`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {selectedrow.zip_code}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Disagree
          </Button>
          <Button onClick={handleAgreeClose} color="primary">
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={del}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{`Something get wrong!`}</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={addQuestion}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{}</DialogTitle>(
        <div className="Circular-Progress">
          <CircularProgress color="primary" />
        </div>
        )
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={opendialog}
        onClose={handleClosedialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Reply to Report</DialogTitle>
        <form onSubmit={EditReply}>
          <DialogContent>
            <DialogContentText>{report}</DialogContentText>

            <TextField
              id="Reply"
              label="Report Reply"
              autoFocus
              multiline
              rows={5}
              variant="outlined"
              fullWidth
              value={reply}
              onChange={(e) => setReply(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClosedialog} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
export default withRouter(Users_report_old);
