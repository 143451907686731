import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { useHistory } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { s3baseUrl } from "../../config/config";
import {
  get_author,
  get_category,
  update_quote,
  get_quotes_detail,
} from "../../DAL/quotes/quotes";
import { useSnackbar } from "notistack";
import { default_image } from "../../assets";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import Fab from "@material-ui/core/Fab";
import Chip from "@material-ui/core/Chip";
var moment = require("moment"); // require
const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: "#37CD77",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    marginTop: "3%",
    color: "white",

    fontSize: "15px",
  },
  txt: {
    color: "black",
    marginTop: "4%",
    marginBottom: 20,
  },
  alert: {
    width: "100%",
    marginBottom: 20,
  },
  avtr: {
    width: 80,
    height: 80,
    cursor: "pointer",
    backgroundColor: "#37CD77",
    color: "white",
  },
  img: {
    width: "100%",
    height: "100%",
  },
  blog_img: {
    marginTop: 10,
    width: 550,
    height: 300,
  },
}));

export default function EditQuotes(props) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const history = useHistory();
  const [authordata, setAuthordata] = React.useState([]);
  const [categorydata, setCategorydata] = React.useState([]);

  const [quotesdata, setQuotesdata] = React.useState([]);
  const [author, setAuthor] = useState("");
  const [category, setCategory] = useState([]);
  const [text, setText] = useState("");
  const [notification_title, setNotification_title] = useState("");
  const [notification_desc, setNotification_desc] = useState("");
  const [selectedDate, setSelectedDate] = React.useState();
  const [selectedTime, setSelectedTime] = React.useState("12:00");
  const [errorflag, setflag] = useState("");
  const [Image, setimage] = React.useState("");
  const [file, setProfileImage] = React.useState("");
  const [imagepath, setImagepath] = React.useState("");
  const [status, setStatus] = React.useState(false);
  const [iswaiting, setiswaiting] = useState(true);
  const [state, setState] = React.useState({
    checkedA: true,
  });
  const handleChange = (event) => {
    console.log(event.target.checked);
    setState({ [event.target.name]: event.target.checked });
  };

  const handleDateChange = (date) => {
    console.log(date.target.value);
    setSelectedDate(date.target.value);
  };
  const handleTimeChange = (time) => {
    console.log(time.target.value);
    setSelectedTime(time.target.value);
  };
  const handleChangeAuthor = (event) => {
    setAuthor(event.target.value);
  };

  const handleChangeCategory = (value) => {
    console.log(value, "value");
    let _catTag = [];
    value.map((x, i) => {
      _catTag.push({ title: x.title, _id: x._id });
    });
    setCategory(_catTag);
    console.log(_catTag, "final tags");
  };

  const handletext = (event) => {
    console.log(event.target.value);
    setText(event.target.value);
  };
  const handleNotificationTitle = (event) => {
    console.log(event.target.value);
    setNotification_title(event.target.value);
  };
  const handleNotificationDesc = (event) => {
    console.log(event.target.value);
    setNotification_desc(event.target.value);
  };

  const fileChangedHandler = (e) => {
    if (e.target.files.length) {
      setimage(e.target.files[0]);
      setProfileImage(URL.createObjectURL(e.target.files[0]));
    }
  };
  const handleback = () => {
    history.goBack();
  };
  const handlesubmit = async (event) => {
    event.preventDefault();
    setiswaiting(true);
    const cat_array = [];
    category.map((x, i) => {
      console.log(x._id);
      cat_array.push(x._id);
    });
    let formData = new FormData(); //formdata object
    formData.append("author", author);
    formData.append("categories", cat_array);
    formData.append("title", text);
    formData.append("notification_title", notification_title);
    formData.append("notification_description", notification_desc);
    //time
    const time = moment(selectedDate + "T" + selectedTime).format(
      "YYYY/MM/DD HH:mm:ss ZZ"
    );

    console.log(time, "time final");
    formData.append("active_at", time);
    if (Image) {
      formData.append("large_image", Image);
    }
    if (Image) {
      formData.append("smaill_image", Image);
    }
    formData.append("notify_all", state.checkedA);
    const quotes_add_resp = await update_quote(formData, props.match.params.id);
    if (quotes_add_resp.code == 200) {
      props.history.push("/QuotesList");
    } else {
      enqueueSnackbar(quotes_add_resp.message, { variant: "error" });
    }
  };
  const author_get = async () => {
    const author_get_resp = await get_author();
    setAuthordata(author_get_resp._authors);
  };
  const category_get = async () => {
    const category_get_resp = await get_category();
    console.log(category_get_resp._categories);
    setCategorydata(category_get_resp._categories);
    get_quotes(category_get_resp._categories);
  };

  const get_quotes = async (category_data) => {
    const quotes_get_resp = await get_quotes_detail(props.match.params.id);
    console.log(quotes_get_resp.media);
    setQuotesdata(quotes_get_resp.media);
    console.log(quotes_get_resp.media.author.name);
    // setAuthor(quotes_get_resp.media.author.name);
    // setAuthor({...author, _id:quotes_get_resp.media.author._id, name: quotes_get_resp.media.author.name});
    // prepare category
    const category_array = [];
    quotes_get_resp.media.categories.map((x) => {
      category_data.map((obj) => {
        if (String(obj._id) == String(x)) {
          category_array.push(obj);
        }
      });
    });

    setCategory(category_array);
    setText(quotes_get_resp.media.title);
    setNotification_title(quotes_get_resp.media.notification_title);
    setNotification_desc(quotes_get_resp.media.notification_description);

    setAuthor(quotes_get_resp.media.author._id);
    const new_date = moment(quotes_get_resp.media.active_at)
      .subtract(2, "minutes")
      .format("YYYY-MM-DD");
    const new_time = moment(quotes_get_resp.media.active_at)
      .subtract(0, "minutes")
      .format("HH:mm");
    console.log(new_time, "NEW TIME");
    const time_array = quotes_get_resp.media.active_at.split(" ");
    setSelectedTime(new_time);
    setSelectedDate(new_date);
    setImagepath(quotes_get_resp.media.large_image);
    setState({ checkedA: quotes_get_resp.media.notify_all });
    setiswaiting(false);
  };
  //console.log(authordata);
  useEffect(() => {
    author_get();
    category_get();
  }, []);
  console.log(author, "image path");

  if (iswaiting == true) {
    return <CircularProgress style={{ marginTop: "15%", marginLeft: "45%" }} />;
  } else {
    return (
      <>
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <div className={classes.paper}>
            <ArrowBackIcon
              style={{
                cursor: "pointer",
                marginLeft: "-550px",
              }}
              onClick={handleback}
            />
            <Typography className={classes.txt} component="h1" variant="h5">
              Edit Quote
            </Typography>
            {errorflag && (
              <Alert className={classes.alert} severity="error">
                {errorflag}
              </Alert>
            )}
            <form className={classes.form} onSubmit={handlesubmit}>
              <Grid container spacing={3}>
                {/* <Grid item xs={12}>
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-outlined-label">
                  Author
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={author}
                    fullWidth
                    onChange={(e) => setAuthor(e.target.value)}
                    label="Author"
                  >
                    {authordata.map((x, i) => {
                      return <MenuItem value={x._id}>{x.name}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid> */}
                <Grid item xs={12}>
                  <FormControl variant="outlined" style={{ width: "100%" }}>
                    <InputLabel htmlFor="age-native-simple">Author</InputLabel>
                    <Select
                      native
                      value={author}
                      onChange={handleChangeAuthor}
                      label="Author"
                      autoFocus
                      fullWidth
                    >
                      {authordata.map((x, i) => {
                        return (
                          <>
                            <option key={i} value={x._id}>
                              {x.name}
                            </option>
                          </>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    id="tags-filled"
                    options={categorydata}
                    value={category}
                    getOptionLabel={(option) => option.title}
                    getOptionSelected={(option, value) =>
                      option._id == value._id
                    }
                    onChange={(event, value) => handleChangeCategory(value)}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option.title}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Categories"
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    label="Pray Request Content"
                    multiline
                    rows="5"
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    id="title"
                    value={text}
                    onChange={handletext}
                    label="Title"
                    name="text"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    value={notification_title}
                    onChange={handleNotificationTitle}
                    name="notification_title"
                    label="Notification Title"
                    type="text"
                    id="notification_title"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    label="Pray Request Content"
                    multiline
                    rows="5"
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    id="notification_desc"
                    value={notification_desc}
                    onChange={handleNotificationDesc}
                    label="Notification Description"
                    name="notification_desc"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    id="selectedTime"
                    type="time"
                    ampm={false}
                    className={classes.textField}
                    value={selectedTime}
                    onChange={handleTimeChange}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    id="selectedDate"
                    type="date"
                    className={classes.textField}
                    value={selectedDate}
                    onChange={handleDateChange}
                    defaultValue={selectedDate}
                  />
                </Grid>

                <Grid item xs={12}>
                  <img
                    className={classes.img}
                    src={
                      file
                        ? file
                        : imagepath !== ""
                        ? s3baseUrl + imagepath
                        : default_image
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <input
                    accept="image/x-png,image/jpeg"
                    hidden
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={fileChangedHandler}
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      component="span"
                      fullWidth
                      variant="outlined"
                      color="primary"
                    >
                      Upload Image
                    </Button>
                  </label>
                </Grid>

                <Grid item xs={6}>
                  Notify All :
                  <FormControlLabel
                    style={{ marginLeft: 10 }}
                    control={
                      <Switch
                        color="primary"
                        checked={state.checkedA}
                        onChange={handleChange}
                        name="checkedA"
                      />
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Edit Quote
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      </>
    );
  }
}
