import { invokeApi } from "../../bl_libs/invokeApi";

export const add_update = async (data) => {
  let requestObj = {
    path: "admin/v1/notify_all",
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

// export const _list = async (page, limit) => {
//     console.log(limit, "PAGE IN DAL");
//     let requestObj = {
//       path: "admin/author/list",
//       method: "GET",
//       headers: {
//         "x-sh-auth": localStorage.getItem("token"),
//       },
//       queryParams: {
//         page: page,
//         limit: limit,
//       },
//     };
//     return invokeApi(requestObj);
//   };
