import React from "react";
import { Switch, Redirect } from "react-router-dom";
import { Navigate } from "react-router-dom";
import {
  PrivateRouteWithLayout,
  // RouteWithLayout,
  PublicRouteWithLayout,
  // PrivateRouteWithLayout,
} from "../components";
import { MainWithSidebar, MinimalLayout } from "../layouts";

import {
  Dashboard,
  Login,
  ListAuthors,
  ChangePassword,
  Addauthor,
  Editauthor,
  User_listing,
  Users_report,
  CategoryList,
  AddCategory,
  PrayerList,
  EditCategory,
  AddPrayer,
  EditPrayer,
  NotifyUpdates,
  QuotesList,
  AddQuotes,
  EditQuotes,
  ScheduledQuotes,
  PendingQuotes,
  CategoryDetail,
  AuthorDetail,
} from "../pages";
import AddGeneralTracks from "../pages/AllTracks/AddGeneralTracks";
import AllTracksList from "../pages/AllTracks/AllTracksList";
import EditGeneralTracks from "../pages/AllTracks/EditGeneralTracks";
import AddPlayList from "../pages/Playlist/AddPlaylist";
import AddQTracks from "../pages/Playlist/AddTracks";
import EditPlayList from "../pages/Playlist/EditPlaylist";
import EditTracks from "../pages/Playlist/EditTracks";
import PlayList from "../pages/Playlist/PlayList";
import TrackList from "../pages/Playlist/TrackList";
import users_report_old from "../pages/User/Users_report/users_report_old";
function Routes() {
  return (
    <>
      <Switch>
        <Redirect exact from="/" to="/login" />
        <PublicRouteWithLayout
          component={Login}
          exact
          layout={MinimalLayout}
          path="/login"
        />
        {/* --------------------------------------- Dashboard --------------------------------------- */}
        <PrivateRouteWithLayout
          component={Dashboard}
          exact
          layout={MainWithSidebar}
          path="/dashboard"
        />
        {/* --------------------------------------- Change Password --------------------------------------- */}
        <PrivateRouteWithLayout
          component={ChangePassword}
          exact
          layout={MainWithSidebar}
          path="/change-password"
        />
        {/* --------------------------------------- Authors --------------------------------------- */}
        <PrivateRouteWithLayout
          component={ListAuthors}
          exact
          layout={MainWithSidebar}
          path="/Authors"
        />
        <PrivateRouteWithLayout
          component={AuthorDetail}
          exact
          layout={MainWithSidebar}
          path="/AuthorQuotesDetail/:id"
        />
        <PrivateRouteWithLayout
          component={Addauthor}
          exact
          layout={MainWithSidebar}
          path="/Addauthor"
        />
        <PrivateRouteWithLayout
          component={Editauthor}
          exact
          layout={MainWithSidebar}
          path="/edit_author/:id"
        />

        {/* --------------------------------------- Users  --------------------------------------- */}
        <PrivateRouteWithLayout
          component={User_listing}
          exact
          layout={MainWithSidebar}
          path="/Users"
        />
        <PrivateRouteWithLayout
          component={users_report_old}
          exact
          layout={MainWithSidebar}
          path="/User_report"
        />
        {/* --------------------------------------- Categories  --------------------------------------- */}
        <PrivateRouteWithLayout
          component={CategoryList}
          exact
          layout={MainWithSidebar}
          path="/Categories"
        />
        <PrivateRouteWithLayout
          component={CategoryDetail}
          exact
          layout={MainWithSidebar}
          path="/CategoriesDetail/:id"
        />
        <PrivateRouteWithLayout
          component={AddCategory}
          exact
          layout={MainWithSidebar}
          path="/Addcategory"
        />
        <PrivateRouteWithLayout
          component={EditCategory}
          exact
          layout={MainWithSidebar}
          path="/Editcategory/:id"
        />
        {/* --------------------------------------- Prayer Request  --------------------------------------- */}
        <PrivateRouteWithLayout
          component={PrayerList}
          exact
          layout={MainWithSidebar}
          path="/PrayerRequest"
        />
        <PrivateRouteWithLayout
          component={AddPrayer}
          exact
          layout={MainWithSidebar}
          path="/Addprayer"
        />
        <PrivateRouteWithLayout
          component={EditPrayer}
          exact
          layout={MainWithSidebar}
          path="/Editprayer/:id"
        />

        {/* --------------------------------------- Notify for new Updates --------------------------------------- */}
        <PrivateRouteWithLayout
          component={NotifyUpdates}
          exact
          layout={MainWithSidebar}
          path="/NotifyUpdates"
        />
        {/* --------------------------------------- Quotes --------------------------------------- */}
        <PrivateRouteWithLayout
          component={QuotesList}
          exact
          layout={MainWithSidebar}
          path="/QuotesList"
        />
        <PrivateRouteWithLayout
          component={AddQuotes}
          exact
          layout={MainWithSidebar}
          path="/QuotesList/AddQuotes"
        />
        <PrivateRouteWithLayout
          component={EditQuotes}
          exact
          layout={MainWithSidebar}
          path="/EditQuotes/:id"
        />
        <PrivateRouteWithLayout
          component={ScheduledQuotes}
          exact
          layout={MainWithSidebar}
          path="/ScheduledQuotes"
        />
        <PrivateRouteWithLayout
          component={PendingQuotes}
          exact
          layout={MainWithSidebar}
          path="/PendingQuotes"
        />
        <PrivateRouteWithLayout
          component={PlayList}
          exact
          layout={MainWithSidebar}
          path="/Playlist"
        />
        <PrivateRouteWithLayout
          component={AddPlayList}
          exact
          layout={MainWithSidebar}
          path="/Playlist/add-playlist"
        />
        <PrivateRouteWithLayout
          component={EditPlayList}
          exact
          layout={MainWithSidebar}
          path="/Playlist/edit-playlist/:id"
        />
        <PrivateRouteWithLayout
          component={TrackList}
          exact
          layout={MainWithSidebar}
          path="/Playlist/trackList/:id"
        />
        <PrivateRouteWithLayout
          component={AddQTracks}
          exact
          layout={MainWithSidebar}
          path="/Playlist/trackList/add-track/:id"
        />
        <PrivateRouteWithLayout
          component={EditTracks}
          exact
          layout={MainWithSidebar}
          path="/Playlist/trackList/edit-track/:id"
        />
        <PrivateRouteWithLayout
          component={AllTracksList}
          exact
          layout={MainWithSidebar}
          path="/allTracks"
        />
        <PrivateRouteWithLayout
          component={AddGeneralTracks}
          exact
          layout={MainWithSidebar}
          path="/allTracks/add-track"
        />
        <PrivateRouteWithLayout
          component={EditGeneralTracks}
          exact
          layout={MainWithSidebar}
          path="/allTracks/edit-track/:id"
        />
      </Switch>
    </>
  );
}

export default Routes;
