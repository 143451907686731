import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { useHistory, useParams } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FileUploadIcon from "@mui/icons-material/FileUpload";

import {
  get_author,
  get_category,
  add_quotes,
  notify_all_quotes,
} from "../../DAL/quotes/quotes";
import { useSnackbar } from "notistack";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import Fab from "@material-ui/core/Fab";
import Chip from "@material-ui/core/Chip";
import { FormHelperText } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/material/styles";
import { addPlaylistApi, addTrackApi } from "../../DAL/Meditation/meditation";

var moment = require("moment"); // require

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: "#37CD77",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    marginTop: "3%",
    color: "white",

    fontSize: "15px",
  },
  txt: {
    color: "black",
    marginTop: "4%",
    marginBottom: 20,
  },
  alert: {
    width: "100%",
    marginBottom: 20,
  },
  avtr: {
    width: 80,
    height: 80,
    cursor: "pointer",
    backgroundColor: "#37CD77",
    color: "white",
  },
  img: {
    width: "100%",
    height: "100%",
  },
  blog_img: {
    marginTop: 10,
    width: 550,
    height: 300,
  },
  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
  },
}));

const Input = styled("input")({
  display: "none",
});

export default function AddPlayList(props) {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [authordata, setAuthordata] = React.useState([]);
  const [categorydata, setCategorydata] = React.useState([]);
  const [author, setAuthor] = useState("");
  const [category, setCategory] = useState([]);
  const [text, setText] = useState("");
  const [notification_title, setNotification_title] = useState("");
  const [notification_desc, setNotification_desc] = useState("");
  const [selectedDate, setSelectedDate] = React.useState();
  const [selectedTime, setSelectedTime] = React.useState("");
  const [errorflag, setflag] = useState("");
  const [Image, setimage] = React.useState("");
  const [file, setProfileImage] = React.useState("");
  const [status, setStatus] = React.useState(false);
  const [iswaiting, setiswaiting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [quotationType, setQuotationType] = React.useState("Publish");
  const [audioFile, setAudio] = React.useState();
  const [audioStatus, setAudioStatus] = useState(false);
  const [oldAudio, setOldAudio] = React.useState("");
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
  });
  const [inputs, setInputs] = useState({
    name: "",
    description: "",
    status: "true",
    audio: {},
    image: {},
    description: "",
  });
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const audioFileChange = (e) => {
    setAudioStatus(true);
    setAudio(e.target.files[0]);
    console.log(e, "ididididi");
  };

  const handleChangeQuotationFor = (event) => {
    setQuotationType(event.target.value);
  };

  const handleDateChange = (date) => {
    //console.log(date.target.value);
    setSelectedDate(date.target.value);
  };
  const handleTimeChange = (time) => {
    //console.log(time.target.value);
    setSelectedTime(time.target.value);
  };
  const handleChangeAuthor = (event) => {
    //console.log(event.target.value, "authordata[0]?._id");
    setAuthor(event.target.value);
  };

  const handleChangeCategory = (value) => {
    //console.log(value, "value");
    let _catTag = [];
    value.map((x, i) => {
      _catTag.push({ title: x.title, _id: x._id });
    });
    setCategory(_catTag);
    //console.log(_catTag, "final tags");
  };
  //console.log(authordata[0]?._id, "authordataauthordata");
  const handletext = (event) => {
    //console.log(event.target.value);
    setText(event.target.value);
  };
  const handleNotificationTitle = (event) => {
    //console.log(event.target.value);
    setNotification_title(event.target.value);
  };
  const handleNotificationDesc = (event) => {
    //console.log(event.target.value);
    setNotification_desc(event.target.value);
  };

  const fileChangedHandler = (e) => {
    if (e.target.files.length) {
      setInputs({
        ...inputs,
        ["image"]: e.target.files[0],
      });
      setimage(e.target.files[0]);
      setProfileImage(URL.createObjectURL(e.target.files[0]));
    }
  };
  const handleback = () => {
    history.goBack();
  };
  const handleDeleteAudio = () => {
    setAudio();
  };
  const handlesubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setiswaiting(true);
    const formData = new FormData();
    formData.append("name", inputs.name);
    formData.append("status", inputs.status);
    formData.append("image", inputs.image);
    formData.append("description", inputs.description);

    console.log(...formData, "form data value");
    const result = await addPlaylistApi(formData);
    if (result.code == 200) {
      setiswaiting(false);
      setLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
      history.goBack();
    } else {
      setiswaiting(false);
      setLoading(false);
      console.log(result, "Api response");
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  //
  const author_get = async () => {
    const author_get_resp = await get_author();
    //console.log(author_get_resp._authors);
    setAuthordata(author_get_resp._authors);
  };
  const category_get = async () => {
    const category_get_resp = await get_category();
    //console.log(category_get_resp._categories);
    setCategorydata(category_get_resp._categories);
  };
  useEffect(() => {
    author_get();
    category_get();
  }, []);
  useEffect(() => {
    setAuthor(authordata[1]?._id);
  }, [authordata]);

  return (
    <>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <div className={classes.paper}>
          <ArrowBackIcon
            style={{
              cursor: "pointer",
              marginLeft: "-550px",
            }}
            onClick={handleback}
          />
          <Typography className={classes.txt} component="h1" variant="h5">
            Add New Playlist
          </Typography>
          {errorflag && (
            <Alert className={classes.alert} severity="error">
              {errorflag}
            </Alert>
          )}
          <form className={classes.form} onSubmit={handlesubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  value={inputs.name}
                  onChange={handleChange}
                  name="name"
                  label="Title"
                  type="text"
                  id="title"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  multiline
                  rows="5"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  id="description"
                  value={inputs.description}
                  onChange={handleChange}
                  label="Description"
                  name="description"
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="demo-simple-select-label">
                    Status *
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="status"
                    value={inputs.status}
                    label="Status *"
                    onChange={handleChange}
                  >
                    <MenuItem value="true">Yes</MenuItem>
                    <MenuItem value="false">No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                {file ? (
                  <>
                    {" "}
                    <img className={classes.img} src={file} />{" "}
                  </>
                ) : null}
              </Grid>

              <Grid item xs={12}>
                <input
                  accept="image/x-png,image/jpeg"
                  hidden
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={fileChangedHandler}
                />
                <label htmlFor="contained-button-file">
                  <Button
                    component="span"
                    fullWidth
                    variant="outlined"
                    color="primary"
                  >
                    Upload Image
                  </Button>
                </label>
              </Grid>

              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  {loading ? "Submitting..." : "Submit"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </>
  );
}
