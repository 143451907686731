import { invokeApi } from "../../bl_libs/invokeApi";

export const Prayer_list = async (page, limit, search) => {
  let requestObj = {
    path: "admin/v1/prayer_request",
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    queryParams: {
      page: page,
      limit: limit,
      search: search,
    },
  };
  return invokeApi(requestObj);
};

export const add_prayer = async (data) => {
  let requestObj = {
    path: "admin/create/pray_requests",
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const delete_prayer = async (_id) => {
  let requestObj = {
    path: "admin/remove_pray_req",
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    queryParams: {
      pray_id: _id,
    },
  };
  return invokeApi(requestObj);
};
export const prayer_search = async (page, search_text) => {
  let requestObj = {
    path: `admin/v1/prayer_request_search?page=${page}&title=${search_text}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const edit_prayer = async (data, id) => {
  let requestObj = {
    path: `admin/edit_pray_req/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const get_prayer_detail = async (id) => {
  let requestObj = {
    path: `admin/get_pray_req/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_approve_detail = async (id) => {
  let requestObj = {
    path: `admin/approve/pray_requests/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_disapprove_detail = async (id) => {
  let requestObj = {
    path: `admin/disapprove/pray_requests/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
