import React, { useState } from "react";
import {
  Grid,
  Card,
  CardHeader,
  Radio,
  Checkbox,
  FormControlLabel,
  Divider,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CardActionArea,
  CardMedia,
  Fab,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Typography from "@material-ui/core/Typography";
import CancelIcon from "@material-ui/icons/Cancel";
import { green } from "@material-ui/core/colors";

const useStyles = makeStyles({
  root: {
    width: 600,
    height: "100%",
    margin: "auto",
  },
  media: {
    height: 140,
    width: 140,
    align: "center",
    margin: "auto",
    marginTop: 10,
  },
  content: {
    marginTop: 10,
  },
});
export default function Details(props) {
  const classes = useStyles();
  const { data } = props;

  return (
    <Card className={classes.root}>
      <IconButton
        onClick={props.handleCloseDetails}
        style={{ float: "right" }}
        color="textSecondary"
        aria-label="close model"
      >
        <HighlightOffIcon />
      </IconButton>

      <CardContent className={classes.content}>
        <Typography
          gutterBottom
          variant="h5"
          component="h2"
          style={{ textAlign: "center" }}
        >
          {/* {details.name} */}
        </Typography>

        <Grid container>
          {/*------------------------------ Name */}
          <Grid item xs={3}>
            <Typography
              variant="body2"
              color="textSecondary"
              component="b"
              style={{}}
            >
              <b>Name :</b>
            </Typography>
          </Grid>
          <Grid item xs={9}>
          {data.full_name ? (
                  <>
                    {data.full_name}
                  </>
                ) : (
                  <>
                    N / A
                  </>
                )}{" "}
          </Grid>
          <Grid item xs={12}>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
          </Grid>

          {/*------------------------------ Email */}
          <Grid item xs={3}>
            <Typography
              variant="body2"
              color="textSecondary"
              component="b"
              style={{}}
            >
              <b>Email</b>
            </Typography>
          </Grid>
          <Grid item xs={9}>
          {data.email ? (
                  <>
                    {data.email}
                  </>
                ) : (
                  <>
                    N / A
                  </>
                )}{" "}
           
          </Grid>
          <Grid item xs={12}>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
          </Grid>

          {/*------------------------------ About */}
          <Grid item xs={3}>
            <Typography
              variant="body2"
              color="textSecondary"
              component="b"
              style={{}}
            >
              <b>About</b>
            </Typography>
          </Grid>
          <Grid item xs={9}>
          {data.about ? (
                  <>
                    {data.about}
                  </>
                ) : (
                  <>
                    N / A
                  </>
                )}{" "}
          </Grid>
          <Grid item xs={12}>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
          </Grid>

           {/*------------------------------ Age */}
          <Grid item xs={3}>
            <Typography
              variant="body2"
              color="textSecondary"
              component="b"
              style={{}}
            >
              <b>Age</b>
            </Typography>
          </Grid>
          <Grid item xs={9}>
          {data.age ? (
                  <>
                    {data.age}
                  </>
                ) : (
                  <>
                    N / A
                  </>
                )}{" "}
          </Grid>
          <Grid item xs={12}>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
          </Grid>

          {/*------------------------------ Gender */}
          <Grid item xs={3}>
            <Typography
              variant="body2"
              color="textSecondary"
              component="b"
              style={{}}
            >
              <b>Gender</b>
            </Typography>
          </Grid>
          <Grid item xs={9}>
          {data.gender ? (
                  <>
                    {data.gender}
                  </>
                ) : (
                  <>
                    N / A
                  </>
                )}{" "}
          </Grid>
          <Grid item xs={12}>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
          </Grid>

          {/*------------------------------ Date of Birth */}
          <Grid item xs={3}>
            <Typography
              variant="body2"
              color="textSecondary"
              component="b"
              style={{}}
            >
              <b>Date of Birth</b>
            </Typography>
          </Grid>
          <Grid item xs={9}>
          {data.date_of_birth ? (
                  <>
                    {data.date_of_birth}
                  </>
                ) : (
                  <>
                    N / A
                  </>
                )}{" "}
          </Grid>
          <Grid item xs={12}>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
          </Grid>

           {/*------------------------------ Achievements */}
           <Grid item xs={3}>
            <Typography
              variant="body2"
              color="textSecondary"
              component="b"
              style={{}}
            >
              <b>Achievements</b>
            </Typography>
          </Grid>
          <Grid item xs={9}>
          {data.achievements ? (
                  <>
                    {data.achievements}
                  </>
                ) : (
                  <>
                    N / A
                  </>
                )}{" "}
          </Grid>
          <Grid item xs={12}>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
          </Grid>


          {/* <Grid item xs={9}>
            <Typography variant="body2" color="textSecondary" component="b">
              <b>
                {data.notification_status ? (
                  <>
                    <Radio checked color="primary" />
                    Yes
                  </>
                ) : (
                  <>
                    <Radio
                      checked
                      color="secondary"
                      style={{ marginLeft: -15 }}
                    />
                    No
                  </>
                )}{" "}
              </b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
          </Grid> */}
          
        </Grid>
      </CardContent>
      {/* </CardActionArea> */}
    </Card>
  );
} //component close
