import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { useHistory } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { s3baseUrl } from "../../../config/config";
import {
  add_author,
  get_author_detail,
  edit_author,
} from "../../../DAL/authors/author";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: "#37CD77",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    marginTop: "3%",
    color: "white",

    fontSize: "15px",
  },
  txt: {
    color: "black",
    marginTop: "4%",
    marginBottom:20
  },
  alert: {
    width: "100%",
    marginBottom: 20,
  },
  avtr: {
    width: 80,
    height: 80,
    cursor: "pointer",
    backgroundColor: "#37CD77",
    color: "white",
  },
  blog_img: {
    marginTop: 10,
    width: 550,
    height: 300,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

export default function Editauthor(props) {
  const classes = useStyles();
  const history = useHistory();
  const [name, set_name] = useState("");
  const [country, set_country] = useState("");
  const [date_of_demise, set_date_of_demise] = useState("");
  const [location, set_location] = useState("");
  const [achivements, set_achivements] = useState("");
  const [dob, setDOB] = useState("");
  const [errorflag, setflag] = useState("");
  const [Image, setimage] = React.useState("");
  const [temp_image, setTempImage] = React.useState("");
  const [file, setProfileImage] = React.useState("");
  const [is_load_data, setLoadData] = React.useState(false);
  const [bio, setBio] = React.useState("");
  const [status, setStatus] = React.useState(false);
  const [iswaiting, setiswaiting] = useState(false);

  const handlename = (event) => {
    set_name(event.target.value);
  };

  const fileChangedHandler = (e) => {
    if (e.target.files.length) {
      setimage(e.target.files[0]);
      setProfileImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleback = () => {
    history.goBack();
  };
  const handlesubmit = async (event) => {
    event.preventDefault();
    setiswaiting(true);
    let formData = new FormData(); //formdata object
    formData.append("name", name);
    formData.append("country", country);
    formData.append("born", dob);
    formData.append("demised", date_of_demise);
    formData.append("bio", bio);
    formData.append("achievements", achivements);
    if (Image) {
      formData.append("image", Image);
    }
    const author_edit_resp = await edit_author(formData, props.match.params.id);
    console.log(author_edit_resp, "RESPONSE");
    if (author_edit_resp.code == 200) {
      props.history.push("/Authors");
    }
  };

  useEffect(async () => {
    const author_detail = await get_author_detail(props.match.params.id);
    set_name(author_detail.author.name);
    set_country(author_detail.author.country);
    setDOB(author_detail.author.born);
    setBio(author_detail.author.bio);
    set_date_of_demise(author_detail.author.demised);
    set_achivements(author_detail.author.achievements);
    setTempImage(author_detail.author.img);
    setLoadData(true);
  }, []);
  return (
    <>
      {is_load_data == false ? (
        <CircularProgress style={{ marginTop: "15%", marginLeft: "45%" }} />
      ) : (
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <div className={classes.paper}>
            <ArrowBackIcon
              style={{
                cursor: "pointer",
                marginLeft: "-550px",
              }}
              onClick={handleback}
            />
            <Typography
              className={classes.txt}
              component="h1"
              variant="h5"
            >Edit Author</Typography>
            {errorflag && (
              <Alert className={classes.alert} severity="error">
                {errorflag}
              </Alert>
            )}
            <form className={classes.form} onSubmit={handlesubmit}>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <input
                  accept="image/x-png,image/jpeg"
                  hidden
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={fileChangedHandler}
                />
                <label htmlFor="contained-button-file">
                  <Avatar
                    alt="Remy Sharp"
                    className={classes.avtr}
                    src={file !== "" ? file : s3baseUrl + temp_image}
                  >
                    <AddAPhotoIcon />
                  </Avatar>
                  <span style={{ marginLeft: 8 }}>200 X 200</span>
                </label>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    value={name}
                    onChange={handlename}
                    label="Name"
                    name="name"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    value={country}
                    onChange={(e) => set_country(e.target.value)}
                    fullWidth
                    id="country"
                    label="Country"
                    name="country"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    type="date"
                    fullWidth
                    value={dob}
                    onChange={(e) => setDOB(e.target.value)}
                    id="date_of_birth"
                    label="Date of birth"
                    name="date_of_birth"
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    type="date"
                    fullWidth
                    value={date_of_demise}
                    onChange={(e) => set_date_of_demise(e.target.value)}
                    name="date_of_demise"
                    label="Date of Demise"
                    id="date_of_demise"
                    className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    rowsMax={3}
                    multiline
                    value={bio}
                    onChange={(e) => setBio(e.target.value)}
                    rows={2}
                    fullWidth
                    name="short_biography"
                    label="Short Biography"
                    type="text"
                    id="short_biography"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    rowsMax={3}
                    multiline
                    rows={2}
                    fullWidth
                    value={achivements}
                    onChange={(e) => set_achivements(e.target.value)}
                    name="achivements"
                    label="Achivements"
                    type="text"
                    id="achivements"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Edit author
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      )}
    </>
  );
}
