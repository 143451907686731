import { invokeApi } from "../../bl_libs/invokeApi";

export const playlistList = async (page, limit, search) => {
  let requestObj = {
    path: `admin/playlist/list_all_play_lists?page=${page}&limit=${limit}&search=${search}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    // queryParams: {
    //   page: page,
    //   limit: limit,
    // },
  };
  return invokeApi(requestObj);
};
export const allPlaylistList = async (page, limit) => {
  let requestObj = {
    path: "admin/playlist/list_all_play_lists_without_pagination",
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    // queryParams: {
    //   page: page,
    //   limit: limit,
    // },
  };
  return invokeApi(requestObj);
};
export const TrackListApi = async (id) => {
  let requestObj = {
    path: `admin/playlist/play_list_detail_by_id/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    // queryParams: {
    //   page: page,
    //   limit: limit,
    // },
  };
  return invokeApi(requestObj);
};
export const TrackListByPlaylistApi = async (id, page, limit, search) => {
  let requestObj = {
    path: `admin/track/all_tracks_by_play_list/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    queryParams: {
      page: page,
      limit: limit,
      search: search,
    },
  };
  return invokeApi(requestObj);
};
export const addTrackApi = async (data) => {
  let requestObj = {
    path: `admin/track/add_new_track`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const EditTrackApi = async (data, id) => {
  let requestObj = {
    path: `admin/track/edit_track/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteTrackApi = async (id) => {
  let requestObj = {
    path: `admin/track/delete_track/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    // postData: data,
  };
  return invokeApi(requestObj);
};
export const addPlaylistApi = async (data) => {
  let requestObj = {
    path: `admin/playlist/add_playList`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const editPlaylistApi = async (data, id) => {
  let requestObj = {
    path: `admin/playlist/edit_playList/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deletePlaylistApi = async (id) => {
  let requestObj = {
    path: `admin/playlist/delete_play_list/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    // postData: data,
  };
  return invokeApi(requestObj);
};

export const scheduled_quotes_list = async (page, limit) => {
  let requestObj = {
    path: `admin/media_images/scheduled_list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    queryParams: {
      page: page,
      limit: limit,
    },
  };
  return invokeApi(requestObj);
};

export const pending_quotes_list = async (page, limit) => {
  let requestObj = {
    path: `admin/media_images/pending_list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    queryParams: {
      page: page,
      limit: limit,
    },
  };
  return invokeApi(requestObj);
};

export const get_author = async (data) => {
  let requestObj = {
    path: "admin/author/list_new",
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const get_category = async (data) => {
  let requestObj = {
    path: "admin/category/list_new",
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const add_quotes = async (data) => {
  let requestObj = {
    path: "admin/media_images/create_quotation",
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const notify_all_quotes = async (_id) => {
  let requestObj = {
    path: "admin/notify_now",
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    queryParams: {
      media_id: _id,
    },
  };
  return invokeApi(requestObj);
};

export const delete_quotes = async (_id) => {
  let requestObj = {
    path: "admin/media_images/delete",
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    queryParams: {
      media_id: _id,
    },
  };
  return invokeApi(requestObj);
};

export const quotes_search = async (search_text, id, from) => {
  let requestObj = {
    path: "admin/v1/search_media",
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },

    queryParams: {
      description: search_text,
      from: from,
      id: id,
    },
  };
  return invokeApi(requestObj);
};

export const scheduled_quotes_search = async (search_text, id, from) => {
  let requestObj = {
    path: "admin/v1/search_media",
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },

    queryParams: {
      description: search_text,
      from: from,
      id: id,
    },
  };
  return invokeApi(requestObj);
};

export const pending_quotes_search = async (search_text, id, from) => {
  let requestObj = {
    path: "admin/v1/search_media",
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },

    queryParams: {
      description: search_text,
      from: from,
      id: id,
    },
  };
  return invokeApi(requestObj);
};

export const get_quotes_detail = async (id) => {
  let requestObj = {
    path: `admin/media_images/get?media_id=${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const update_quote = async (data, id) => {
  let requestObj = {
    path: `admin/media_images/update/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
