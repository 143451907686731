import { CircularProgress, Grid } from "@material-ui/core";
import CardBox from "./components/Card/Card";
import CategoryIcon from "@material-ui/icons/Category";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import GroupIcon from "@material-ui/icons/Group";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import { useEffect, useState } from "react";
import { _dashboard_counts } from "../../DAL/dashboard/dashboard";
import { useSnackbar } from "notistack";
import PersonIcon from "@material-ui/icons/Person";
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
function Dashboard(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});

  const FetchDashboardCounts = async () => {
    const resp = await _dashboard_counts();
    if (resp.code === 200) {
      setData({
        user_count: resp.user_count,
        pray_count: resp.pray_count,
        media_count: resp.media_count,
        scheduled_media: resp.scheduled_media,
        media_pending: resp.media_pending,
        total_author: resp.total_author,
        total_category: resp.total_category,
      });
      setIsLoading(false);
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };
  useEffect(() => {
    FetchDashboardCounts();
  }, []);

  if (isLoading) {
    return (
      <div className="Loader">
        <CircularProgress />
      </div>
    );
  }

  const cardsData = [
    {
      title: "Total Users",
      icon: <GroupIcon />,
      color: "#104B4F",
      count: data.user_count,
    },
    {
      title: "Quotes",
      icon: <FormatQuoteIcon />,
      color: "#104B4F",
      count: data.media_count,
    },
    {
      title: "Scheduled Quotes",
      icon: <FormatQuoteIcon />,
      color: "#104B4F",
      count: data.scheduled_media,
    },
    {
      title: " PendingQuotes",
      icon: <FormatQuoteIcon />,
      color: "#104B4F",
      count: data.media_pending,
    },
    {
      title: "Pray count",
      icon: <AccountBoxIcon />,
      color: "#104B4F",
      count: data.pray_count,
    },
    {
      title: "Authors",
      icon: <PersonIcon />,
      color: "#104B4F",
      count: data.total_author,
    },
    {
      title: "Categories",
      icon: <CategoryIcon />,
      color: "#104B4F",
      count: data.total_category,
    },
  ];

  return (
    <div>
      <Grid
        container
        direction="row"
        justify="left"
        alignItems="center"
        spacing={2}
      >
        {cardsData.map((card) => (
          <Grid item xs={12} sm={12} md={4} lg={4} style={{ marginTop: 20 }}>
            <CardBox data={card} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default Dashboard;
