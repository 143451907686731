import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { useHistory } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { withStyles } from '@material-ui/core/styles';
import { purple } from '@material-ui/core/colors';
import {
    get_prayer_detail,
  edit_prayer,
} from "../../DAL/prayer/prayer";
import { useSnackbar } from "notistack";

const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: "#37CD77",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    marginTop: "3%",
    color: "white",

    fontSize: "15px",
  },
  txt: {
    color: "black",
    marginTop: "4%",
    marginBottom:20
  },
  alert: {
    width: "100%",
    marginBottom: 20,
  },
  avtr: {
    width: 80,
    height: 80,
    cursor: "pointer",
    backgroundColor: "#37CD77",
    color: "white",
  },
  blog_img: {
    marginTop: 10,
    width: 550,
    height: 300,
  },
}));

export default function EditPrayer(props) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [title, setTitle] = useState("")
  const [errorflag, setflag] = useState("");
  const [Image, setimage] = React.useState("");
  const [temp_image, setTempImage] = React.useState("");
  const [file, setProfileImage] = React.useState("");
  const [is_load_data, setLoadData] = React.useState(false);
  const [iswaiting, setiswaiting] = useState(false);

  
  const handletitle = (event) => {
    setTitle(event.target.value);
  };

 

  const handleback = () => {
    history.goBack();
  };
  const handlesubmit = async (event) => {
    event.preventDefault();
    setiswaiting(true);
    let formData = new FormData(); //formdata object
    formData.append("title", title);

    const prayer_edit_resp = await edit_prayer(formData, props.match.params.id);
    console.log(prayer_edit_resp, "RESPONSE");
    if (prayer_edit_resp.code == 200) {
      props.history.push("/PrayerRequest");
    } else {
      enqueueSnackbar(prayer_edit_resp.message, { variant: "error" });
     }
  };

  useEffect(async () => {
    const prayer_detail = await get_prayer_detail(props.match.params.id);
    console.log(prayer_detail.pray_res.title);
    setTitle(prayer_detail.pray_res.title);
    setLoadData(true);
  }, []);
  return (
    <>
      {is_load_data == false ? (
        <CircularProgress style={{ marginTop: "15%", marginLeft: "45%" }} />
      ) : (
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <div className={classes.paper}>
            <ArrowBackIcon
              style={{
                cursor: "pointer",
                marginLeft: "-550px",
              }}
              onClick={handleback}
            />
            <Typography
              className={classes.txt}
              component="h1"
              variant="h5"
            >Edit Prayer Request</Typography>
            {errorflag && (
              <Alert className={classes.alert} severity="error">
                {errorflag}
              </Alert>
            )}
            <form className={classes.form} onSubmit={handlesubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    multiline
                    rows="5"
                    required
                    fullWidth
                    id="title"
                    value={title}
                    onChange={handletitle}
                    label="Pray Request Content"
                    name="title"
                    autoFocus
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Edit Prayer
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      )}
    </>
  );
}
