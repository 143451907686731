import { invokeApi } from "../../bl_libs/invokeApi";

export const Author_list = async (search, page, limit) => {
  console.log(limit, "PAGE IN DAL");
  let requestObj = {
    // path: "admin/author/list",
    path: `admin/author/list?search=${search}&page=${page}&limit=${limit}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const Author_Detail_list = async (page, id) => {
  let requestObj = {
    path: `admin/v1/media_images_by_author/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    queryParams: {
      page: page,
    },
  };
  return invokeApi(requestObj);
};
export const author_search = async (search_text) => {
  let requestObj = {
    path: `admin/v1/author_search?name=${search_text}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const author_detail_search = async (search_text, id) => {
  let requestObj = {
    path: `admin/v1/media_images_by_author_search/${id}?title=${search_text}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const add_author = async (data) => {
  let requestObj = {
    path: "admin/author/add",
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const delete_author = async (id) => {
  let requestObj = {
    path: "admin/author/delete",
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    queryParams: {
      author_id: id,
    },
  };
  return invokeApi(requestObj);
};

export const get_author_detail = async (id) => {
  let requestObj = {
    path: `admin/author/get`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    queryParams: {
      author_id: id,
    },
  };
  return invokeApi(requestObj);
};
export const edit_author = async (data, id) => {
  let requestObj = {
    path: `admin/author/update/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
