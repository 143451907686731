import React from "react";
import { filter } from "lodash";
// import { Icon } from "@iconify/react";
// import { sentenceCase } from "change-case";
import { useEffect, useState } from "react";
// import plusFill from "@iconify/icons-eva/plus-fill";
// import { Link as RouterLink } from "react-router-dom";
import { useHistory } from "react-router-dom";

// import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  Slide,
  CircularProgress,
} from "@mui/material";
// components
// import ViewCategory from "./ViewCategory";
// import Page from "../../components/Page";
// import Label from "../../components/Label";
// import Scrollbar from "../../components/Scrollbar";
import SearchNotFound from "../../components/SearchNotFound";
// import {
//   UserListHead,
//   UserListToolbar,
//   UserMoreMenu,
// } from "../../components/_dashboard/user";
import UserListHead from "../../components/Table/UserListHead";
import UserListToolbars from "../../components/Table/UserListToolbars";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

// import CustomPopover from "../../components/CustomPopover";
// import UpdateCategory from "../Category/UpdateCategory";

import { useSnackbar } from "notistack";
// import CategoryCofirmation from "../Category/CategoryConfirmation";
// import Loader from "../../components/Loader/Loader";

// import { _tag_list, _delete_tag } from "../../DAL/tags/tags";
// import AddTags from "./AddTags";
// import UpdateTag from "./UpdateTag";

import { s3baseUrl } from "../../config/config";
import {
  delete_prayer,
  Prayer_list,
  prayer_search,
} from "../../DAL/prayer/prayer";
import {
  delete_quotes,
  pending_quotes_list,
  pending_quotes_search,
  quotes_list,
  quotes_search,
  scheduled_quotes_list,
  scheduled_quotes_search,
} from "../../DAL/quotes/quotes";
import moment from "moment";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "#", label: "#", alignRight: false },
  { id: "name", label: "Schedule At", alignRight: false },
  { id: "image", label: "Status", alignRight: false },
  { id: "country", label: "Notification Description", alignRight: false },
  { id: "author", label: "Author", alignRight: false },
  { id: "picture", label: "Thumbnail", alignRight: false },
  { id: "action", label: "Action", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (row) => row.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ListAuthors() {
  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();

  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [page, setPage] = useState(0);
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalPages, setTotalPages] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);
  const [DrawerOpen, setDrawerOpen] = useState(false);
  const [viewDrawerOpen, setViewDrawerOpen] = useState(false);
  const [addDrawerOpen, setAddDrawerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [selectedrow, setrowdata] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [isdata, setisdata] = React.useState(false);
  const [Id, setId] = React.useState();
  const [filterNameStatus, setFilterNameStatus] = React.useState(false);

  const get_authors = async () => {
    let postData = {
      search: filterName,
    };
    setIsLoading(true);
    const res = await pending_quotes_list(filterName, page, rowsPerPage);

    if (res.code === 200) {
      setIsLoading(false);
      //console.log(res, "result");
      const tags = res.media.map((author, index) => {
        return {
          ...author,
          name: author.title,
          image: author.large_image,
          author: author?.author?.name,
          description: author.notification_description,
          prayerCount: author.liked_count,
          status: author.publish,
          createdAt: author.active_at,
          count: index + 1 + rowsPerPage * page,
        };
      });

      setData(tags);
      setTotalCount(res.total_count);
      setIsLoading(false);
    }
  };
  const handleSearchName = () => {
    //console.log(filterName, "filterName for search");
    get_authors();
    setFilterNameStatus(true);
  };

  const deleteuser = async () => {
    setIsLoading(true);
    setOpen(false);
    setData([]);
    setisdata(false);
    const delete_author_resp = await delete_quotes(selectedrow._id);
    if (delete_author_resp.code == 200) {
      //console.log("deleted");
      get_authors(page);
    } else {
      //console.log("not deleted");
      setIsLoading(false);
    }
  };
  // const hanldeSearchChange = async (e) => {
  //   // setSearch(filterName);
  //   setData([]);
  //   const data_search = await pending_quotes_search(
  //     filterName,
  //     Id,
  //     "scheduled"
  //   );
  //   if (data_search.code == 200) {
  //     //console.log(data_search, "DATA SEARCH");
  //     const tags = data_search.media.map((author, index) => {
  //       return {
  //         ...author,
  //         name: author.title,
  //         image: author.large_image,
  //         author: author?.author?.name,
  //         description: author.notification_description,
  //         prayerCount: author.liked_count,
  //         status: author.publish,
  //         createdAt: author.createdAt,
  //         count: index + 1 + rowsPerPage * page,
  //       };
  //     });

  //     if (data_search.media.length != 0) {
  //       setData(tags);
  //       // setTotalCount(data_search.total);
  //     }
  //     setisdata(true);
  //   }
  // };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    setPage(0);
  };

  const emptyRows =
    // page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;
    page > 0 && 0;

  const filteredUsers = applySortFilter(
    data,
    getComparator(order, orderBy)
    // filterName
  );
  // //console.log(filteredUsers, "filteredUsers");

  const isUserNotFound = filteredUsers.length === 0;

  const handleDeleteClick = (row) => {
    //console.log(row, "rowwwwwwwwwwww");
    setSelectedRow(row);
    setOpenDelete(true);
  };
  const Addquotes = () => {
    history.push("/QuotesList/AddQuotes");
  };

  const handleUpdate = (row) => {
    setSelectedRow(row);
    handleOpenChangeTag();
  };

  const handleEdit = (row) => {
    //console.log(row, "row");
    // navigate(`/edit_author/${row._id}`);
    // navigate(`/member/goal-statement-Question-reply/${params.id}`, {
    //   state: value,
    // });
  };
  const handleOpenViewDrawer = (row) => {
    setViewDrawerOpen(true);
  };

  //   const handleCloseViewDrawer = () => {
  //     // setSelectedRow(row);
  //     setViewDrawerOpen(false);
  //   };

  const handleOpenChangeTag = () => {
    setDrawerOpen(true);
  };

  const Addauthor = () => {
    history.push("/Addauthor");
  };

  const handleClickOpen = (rowdata) => {
    //console.log(rowdata, "okokoko");
    setrowdata(rowdata);
    setOpen(true);
  };

  const MENU_OPTIONS = [
    {
      label: "Update",
      icon: "akar-icons:edit",
      handleClick: handleUpdate,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleDeleteClick,
    },
    // {
    //   label: "View",
    //   icon: "carbon:view",
    //   handleClick: handleView,
    // },
  ];

  useEffect(() => {
    get_authors();
  }, [page, rowsPerPage]);
  useEffect(() => {
    get_authors();
  }, []);

  // useEffect(() => {
  //   hanldeSearchChange(filterName);
  //   //console.log("change");
  // }, []);
  useEffect(() => {
    if (!filterNameStatus) {
      return;
    }
    get_authors();
  }, []);

  // if (isLoading) {
  //   return <CircularProgress className="Loader" />;
  // }
  //console.log(filterName, "filterName");
  return (
    <>
      <>
        {/* <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton> */}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Pending Quotes
          </Typography>
          {/* <Button
            className="small-contained-button"
            onClick={Addquotes}
            variant="contained"
            color="primary"
            // startIcon={<Icon icon={plusFill} />}
          >
            Add Quote
          </Button> */}
        </Stack>

        <Card>
          <UserListToolbars
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            onSearch={handleSearchName}
          />

          <TableContainer>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={data.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {data
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const {
                      _id,
                      name,
                      content,
                      prayerCount,
                      status,
                      createdAt,
                      count,
                      description,
                      author,
                      image,
                    } = row;

                    return (
                      <TableRow hover key={_id}>
                        <TableCell>
                          {page == 0
                            ? index + 1
                            : count.sort(function (a, b) {
                                return a - b;
                              })}
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">
                            {moment(createdAt)
                              .subtract(0, "hours")
                              .format("DD MMMM , YYYY hh:mma")}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography className="pl-2" variant="subtitle2">
                            {status == false ? "Pending" : "Approved"}
                          </Typography>
                        </TableCell>
                        <TableCell className="pr-0" align="left">
                          {description}
                        </TableCell>
                        <TableCell className="pr-0" align="left">
                          {author ? author : "N/A"}
                        </TableCell>{" "}
                        <TableCell className="pr-0" align="left">
                          {image && (
                            <img
                              src={s3baseUrl + image}
                              height={50}
                              width={50}
                            ></img>
                          )}
                        </TableCell>
                        <TableCell align="left">
                          <EditIcon
                            className="pointer"
                            onClick={() =>
                              history.push({
                                pathname: `/EditQuotes/${row._id}`,
                                user: row,
                              })
                            }
                          />
                          <DeleteIcon
                            className="pointer"
                            onClick={() => handleClickOpen(row)}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={4} />
                  </TableRow>
                )}
              </TableBody>
              {isLoading ? (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                data.length < 1 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                        <SearchNotFound />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )
              )}
            </Table>
          </TableContainer>

          {/* <CategoryCofirmation
            open={openDelete}
            setOpen={setOpenDelete}
            title={"Are you sure you want to delete this Tag?"}
            handleAgree={handleDelete}
          /> */}

          <TablePagination
            rowsPerPageOptions={[10, 50, 100, 150]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </>
      {/* <UpdateTag
        OpenDrawer={DrawerOpen}
        onOpenDrawer={handleOpenChangeTag}
        onCloseDrawer={handleCloseChangeTag}
        selectedRow={selectedRow}
        get_tag_list={get_tag_list}
      /> */}
      {/* <AddTags
        AddOpenDrawer={addDrawerOpen}
        OpenDrawer={handleOpenAddTags}
        CloseDrawer={handleCloseAddTags}
        get_tag_list={get_tag_list}
      /> */}
      {/* <ViewCategory
        OpenDrawer={viewDrawerOpen}
        onOpenDrawer={handleOpenViewDrawer}
        onCloseDrawer={handleCloseViewDrawer}
        selectedRow={selectedRow}
        get_category_list={get_category_list}
      /> */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{`Are you sure you want to Delete ${selectedrow.name}`}</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Disagree
          </Button>
          <Button onClick={deleteuser} color="primary">
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
